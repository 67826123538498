import React from 'react'
import Layouts from './Layouts'
import SetKKM from '../components/admin/SetKKM'

const Kkm = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <SetKKM url={url} content={'Set KKM'} />
      </Layouts>
    </div>
  )
}

export default Kkm
