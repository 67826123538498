import React from 'react'
import Layouts from './Layouts'
import DetailRombel from '../components/master/DetailRombel'

const Detail = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <DetailRombel url={url} content="Detail Rombel" />
      </Layouts>
    </div>
  )
}

export default Detail
