import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';


const Sidebar = ({ url }) => {
  const { user } = useSelector(
    (state) => state.auth
  );
  const [aku, setAku] = useState(null);
  const [ajar, setAjar] = useState([]);
  const [lengthAjar, setLengthAjar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [waliKelas, setWaliKelas] = useState([]);
  const [kakomli, setKakomli] = useState([]);
  const [statusWakel, setStatusWakel] = useState(0);
  const [statusKakomli, setStatusKakomli] = useState(0)

  useEffect(() => {
    setAku(user && user.user);
  }, [user]);

  // const sideMenu = () => {
  //   $('.side-menu-wrap ul li').has('.sub-menu').addClass('has-sub-menu');
  //   $('.side-menu-wrap ul li.has-sub-menu > a').off('click').on('click', function (e) { // Ensure event handler is not duplicated
  //     e.preventDefault();
  //     e.stopPropagation(); // Stop event bubbling

  //     var $this = $(this);
  //     var checkElement = $this.next('.sub-menu');

  //     if (checkElement.is(':visible')) {
  //       checkElement.slideUp(300).removeClass('menu-show');
  //       $this.parent('li').removeClass('active');
  //     } else {
  //       var parent = $this.parents('ul').first();
  //       var ul = parent.find('ul:visible').slideUp(300).removeClass('menu-show');
  //       var parent_li = $this.parent('li');

  //       checkElement.slideDown(300).addClass('menu-show');
  //       parent.find('li.active').removeClass('active');
  //       parent_li.addClass('active');
  //     }
  //   });
  // };

  const sideMenu = () => {
    $('.side-menu-wrap ul li').has('.sub-menu').addClass('has-sub-menu');
    $('.side-menu-wrap ul li.has-sub-menu > a').off('click').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();

      var $this = $(this);
      var checkElement = $this.next('.sub-menu');

      if (checkElement.is(':visible')) {
        checkElement.slideUp(300).removeClass('menu-show');
        $this.parent('li').removeClass('active');
      } else {
        var parent = $this.parents('ul').first();
        var ul = parent.find('ul:visible').slideUp(300).removeClass('menu-show');
        var parent_li = $this.parent('li');

        checkElement.slideDown(300).addClass('menu-show');
        parent.find('li.active').removeClass('active');
        parent_li.addClass('active');
      }
    });
  };


  const fetchAjar = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.post(url + "guru-ajar", {
        guru_email: localStorage.getItem("email")
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAjar(response.data.data);
      setLengthAjar(response.data.data.length);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWaliKelas = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-wali-kelas", {
        wali_kelas: localStorage.getItem("email")
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWaliKelas(response.data.data);
      setStatusWakel(response.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKakomli = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-kakomli", {
        email: localStorage.getItem("email")
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKakomli(response.data.data);
      setStatusKakomli(response.data.status);
    } catch (error) {
      console.log(error);
    }
  };





  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === '2') {
      fetchAjar();
      fetchWaliKelas();
      fetchKakomli();
    }
  }, []);

  useEffect(() => {
    sideMenu();

    // Cleanup function to remove event handlers
    return () => {
      $('.side-menu-wrap ul li.has-sub-menu > a').off('click');
    };
  }, [ajar]);



  return (
    <div>
      <aside className="sidebar-wrapper">
        <div className="logo-wrapper">
          <a href="index.html" className="admin-logo">
            <img src="/assets/images/tes.png" alt="" className="sp_logo" />
            <img src="/assets/images/smk.png" alt="" className="sp_mini_logo" />
          </a>
        </div>
        <div className="side-menu-wrap">
          <ul className="main-menu">

            {localStorage.getItem("role") === '1' ? (
              <React.Fragment>
                <li>
                  <NavLink to="/dashboard" className="active">
                    <span className="icon-menu feather-icon">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="menu-text">
                      Dashboard
                    </span>
                  </NavLink>
                </li>

                <li>
                  <a href="javascript:void(0);">
                    <span className="icon-menu feather-icon">
                      <i className="fas fa-cog"></i>
                    </span>
                    <span className="menu-text">
                      Settings
                    </span>
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/tahun-pelajaran">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Tahun Pelajaran
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/semester">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Semester
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/set-kurikulum">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Set Kurikulum
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/mode-rapor">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Mode Rapor
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="javascript:void(0);">
                    <span className="icon-menu feather-icon">
                      <i className="fas fa-database"></i>
                    </span>
                    <span className="menu-text">
                      Master Data
                    </span>
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/master-jurusan">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Data Jurusan
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/master-rombel">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Data Rombel / Kelas
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/kakomli">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Data Kakomli
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/master-siswa">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Data Siswa
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/master-guru">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Data Guru
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/master-mapel">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Data Mata Pelajaran
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/template-cetak">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Template Cetak
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/tugas-guru">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Set Tugas Guru
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="javascript:void(0);">
                    <span className="icon-menu ">
                      <i className="fa fa-retweet"></i>
                    </span>
                    <span className="menu-text">
                      Manage Data
                    </span>
                  </a>
                  <ul className="sub-menu">


                    <li>
                      <NavLink to="/kenaikan-kelas" className="active">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Kenaikan Kelas
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/pindah-kelas" className="active">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Pindah Kelas
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/riwayat-akademik" className="active">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Riwayat Akademik
                        </span>
                      </NavLink>
                    </li>

                  </ul>
                </li>



                <li>
                  <a href="javascript:void(0);">
                    <span className="icon-menu feather-icon">
                      <i className="fas fa-file"></i>
                    </span>
                    <span className="menu-text">
                      Rapor
                    </span>
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/kkm">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          KKM
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/penanggalan-rapor">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Penanggalan Rapor
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/status-nilai">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Status Nilai
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dkn-perkelas">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          DKN/Legger Per Kelas
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dkn-perjurusan">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          DKN/Legger Per Jurusan
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/cetakrapor">
                        <span className="icon-dash">
                        </span>
                        <span className="menu-text">
                          Cetak Rapor
                        </span>
                      </NavLink>
                    </li>

                  </ul>
                </li>


              </React.Fragment>
            ) : (
              // Area Guru
              <React.Fragment>
                <li>
                  <NavLink to="/dashboard-guru" className="active">
                    <span className="icon-menu feather-icon">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="menu-text">
                      Dashboard
                    </span>
                  </NavLink>
                </li>
                <hr />
                <p className='px-3'>Penilaian Anda</p>

                {lengthAjar > 0 && (
                  Object.entries(ajar.reduce((acc, item) => {
                    const { kode_mp, rombel_nama_ } = item;
                    if (!acc[kode_mp]) {
                      acc[kode_mp] = [];
                    }
                    acc[kode_mp].push(rombel_nama_);
                    return acc;
                  }, {})).map(([kode_mp, rombel_namas]) => (
                    <li key={kode_mp} className="has-sub-menu">
                      <a href="javascript:void(0);" onClick={(e) => e.preventDefault()}>
                        <span className="icon-menu feather-icon">
                          <i className="fas fa-book"></i>
                        </span>
                        <span className="menu-text">
                          {kode_mp}
                        </span>
                      </a>
                      <ul className="sub-menu">
                        {rombel_namas.map((rombel_nama_, index) => (
                          <li key={index}>
                            <NavLink to={`/penilaian/${kode_mp}/${rombel_nama_}`}>
                              <span className="icon-dash"></span>
                              <span className="menu-text">
                                {kode_mp} - {rombel_nama_}
                              </span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))
                )}

                {/* Wali Kelas */}
                {statusWakel === true && (
                  <React.Fragment>
                    <p className='px-3 mt-3'>Tugas Tambahan</p>

                    <li>
                      <a href="javascript:void(0);">
                        <span className="icon-menu feather-icon">
                          <i className="fas fa-list-alt"></i>
                        </span>
                        <span className="menu-text">
                          Wali Kelas
                        </span>
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={`/guru/absensi/${waliKelas.nama_rombel}`}>
                            <span className="icon-dash">
                            </span>
                            <span className="menu-text">
                              Absensi
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={`/guru/ekstrakurikuler/${waliKelas.nama_rombel}`}>
                            <span className="icon-dash">
                            </span>
                            <span className="menu-text">
                              Ekstrakurikuler
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={`/guru/status-nilai/${waliKelas.nama_rombel}`}>
                            <span className="icon-dash">
                            </span>
                            <span className="menu-text">
                              Status Nilai
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={`/guru/rapor/${waliKelas.nama_rombel}`}>
                            <span className="icon-dash">
                            </span>
                            <span className="menu-text">
                              Rapor Siswa
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={`/legger/${waliKelas.nama_rombel}`}>
                            <span className="icon-dash">
                            </span>
                            <span className="menu-text">
                              Legger / DKN
                            </span>
                          </NavLink>
                        </li>


                      </ul>
                    </li>
                  </React.Fragment>
                )}

                {/* KAKOMLI */}
                {statusKakomli === true && (
                  <React.Fragment>
                    <p className='px-3 mt-3'>Tugas Tambahan</p>

                    <li>
                      <a href="javascript:void(0);">
                        <span className="icon-menu feather-icon">
                          <i className="fas fa-list-alt"></i>
                        </span>
                        <span className="menu-text">
                          Kakomli
                        </span>
                      </a>
                      <ul className="sub-menu">

                        {kakomli.map((item, index) => (
                          <li key={index}>
                            <NavLink to={`/dkn-jurusan/${item.level}/${item.kode_jurusan}/${item.id}`}>
                              <span className="icon-dash">
                              </span>
                              <span className="menu-text">
                                DKN - {item.level === '1' ? '10' : item.level === '2' ? '11' : item.level === '3' ? '12' : item.level}.{item.kode_jurusan}
                              </span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
            }


          </ul>
        </div>
      </aside>
    </div>
  )
}

export default Sidebar;