import React from 'react'
import CetakRapor from '../components/admin/CetakRapor'
import Layouts from './Layouts'

const CtkRapor = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <CetakRapor url={url} content={'Raport Siswa'} />
      </Layouts>
    </div>
  )
}

export default CtkRapor
