import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';




const Ekskul = ({ url, content }) => {
  const { nama_rombel } = useParams();
  const [ekskul, setEkskul] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jumlahSiswa, setJumlahSiswa] = useState({});

  const fetchEkskul = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-ekskul-by-kelas", {
        kelas: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setEkskul(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const fetchJumlahSiswa = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-jml-peserta-ekskul", {
        id_ekskul: id,
        rombel: nama_rombel,

      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setJumlahSiswa(prevState => ({ ...prevState, [id]: response.data.data }));
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    fetchEkskul();
  }, []);

  useEffect(() => {
    ekskul.forEach(item => {
      fetchJumlahSiswa(item.id);
    });
  }, [ekskul]);


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Ekstrakurikuler</th>
                        <th className='text-center'>Jenis</th>
                        <th className='text-center'>Jumlah Siswa</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? <tr><td colSpan="5" className="text-center"><Spinner animation="border" size="sm" /></td></tr> :
                        ekskul.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.nama_ekstra}</td>
                            <td className='text-center'>
                              {item.jenis === '1' ? 'Wajib' : item.jenis === '2' ? 'Pilihan' : item.jenis}
                            </td>
                            <td className='text-center'>
                              {jumlahSiswa[item.id] !== undefined ? jumlahSiswa[item.id] : <Spinner animation="border" size="sm" />}
                            </td>
                            <td className='text-center'>
                              {item.jenis === '1' && <NavLink to={`/wakel/input-ekstra/${item.id}/${nama_rombel}`} className="btn btn-primary sm-btn">Input Nilai</NavLink>}

                              {item.jenis === '2' && <NavLink to={`/wakel/input-ekstra-pilihan/${item.id}/${nama_rombel}`} className="btn btn-primary sm-btn">Pilih Siswa</NavLink>}


                            </td>
                          </tr>
                        ))
                      }


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ekskul
