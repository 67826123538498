import React from 'react'
import Layouts from './Layouts'
import SetTanggal from '../components/admin/SetTanggal'

const Tanggal = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <SetTanggal url={url} content={'Set Penanggalan Rapor'} />
      </Layouts>
    </div>
  )
}

export default Tanggal
