import React from 'react'
import Layouts from './Layouts'
import PindahKls from '../components/admin/PindahKls'

const PindahKelas = ({ url }) => {
  return (
    <div>
      <Layouts url={url}  >
        <PindahKls url={url} content={'Pindah Kelas'} />
      </Layouts>
    </div>
  )
}

export default PindahKelas
