import React from 'react'
import Siswa from '../components/master/Siswa'
import Layouts from './Layouts'
import Jurusan from '../components/master/Jurusan'
import Rombel from '../components/master/Rombel'
import Guru from '../components/master/Guru'
import Mapel from '../components/master/Mapel'
import Kakomli from '../components/master/Kakomli'

const Masters = ({ content, url }) => {
  return (
    <div>
      <Layouts url={url}>
        {content === 'Data Siswa' ? <Siswa content={content} url={url} /> : null}
        {content === 'Data Jurusan' ? <Jurusan content={content} url={url} /> : null}
        {content === 'Data Rombel / Kelas' ? <Rombel content={content} url={url} /> : null}
        {content === 'Data Guru' ? <Guru content={content} url={url} /> : null}
        {content === 'Data Mata Pelajaran' ? <Mapel content={content} url={url} /> : null}
        {content === 'Kakomli' ? <Kakomli content={content} url={url} /> : null}
      </Layouts>
    </div>
  )
}

export default Masters
