import React from 'react'
import Layouts from './Layouts'
import Raport from '../components/wakel/Raport'

const Rapor = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <Raport url={url} content={'Raport Siswa'} />
      </Layouts>
    </div>
  )
}

export default Rapor
