import React, { useState, useEffect } from 'react'
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { Button, Modal, Form, Spinner } from 'react-bootstrap';



const InputEkstraPilihan = ({ url, content }) => {

  const { id_ekstra, nama_rombel } = useParams()
  const navigate = useNavigate()
  const [ekskul, setEkskul] = useState([])
  const [siswa, setSiswa] = useState([])
  const [loading, setLoading] = useState(false)
  const [nilai, setNilai] = useState([])
  const [headerInput, setHeaderInput] = useState('')
  const [loadingSiswa, setLoadingSiswa] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loadingNilai, setLoadingNilai] = useState(false)


  const fetchEkskul = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'get-ekskul-by-id', {
      id: id_ekstra,
      rombel: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setEkskul(response.data.data)
  }

  const fetchSiswa = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'siswa-by-rombel', {
      rombel_nama: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    const siswaData = response.data.data
    const siswaWithPilihan = await Promise.all(siswaData.map(async (item) => {
      const pilihanResponse = await axios.post(url + 'get-siswa-pilihan-ekskul', {
        nis: item.nis,
        rombel: nama_rombel
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return { ...item, pilihanSiswa: pilihanResponse.data.data }
    }))


    setSiswa(siswaWithPilihan)

    setLoading(false)
  }

  const fetchNilai = async (nis) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'get-nilai-ekskul-by-siswa', {
      nis: nis,
      id_ekstra: id_ekstra,
      rombel: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = response.data.data;
    return data && data.eks_ket_nilai ? data.eks_ket_nilai : '';
  }

  const handleHeaderInputChange = (e) => {
    const value = e.target.value
    setHeaderInput(value)
    const updatedNilai = {}
    siswa.forEach(item => {
      updatedNilai[item.nis] = value
    })
    setNilai(updatedNilai)
  }



  useEffect(() => {
    fetchEkskul()
    fetchSiswa()
  }, [])

  useEffect(() => {
    const fetchAllNilai = async () => {
      setLoadingNilai(true)
      const nilaiData = {}
      for (const item of siswa) {
        nilaiData[item.nis] = await fetchNilai(item.nis)
      }
      setNilai(nilaiData)
      setLoadingNilai(false)
    }
    if (siswa.length > 0) {
      fetchAllNilai()
    }
  }, [siswa])

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleCheckboxChange = (nis) => {
    const updatedSiswa = siswa.map(item =>
      item.nis === nis ? { ...item, isChecked: !item.isChecked } : item
    );
    setSiswa(updatedSiswa);
  };

  const handleSave = async () => {
    setLoadingSave(true);
    const token = localStorage.getItem('token');
    const selectedNis = siswa.filter(item => item.isChecked).map(item => item.nis);

    if (selectedNis.length === 0) {
      toast.error('Tidak ada siswa yang dipilih');
      setLoadingSave(false);
      return;
    }


    try {
      console.log(selectedNis)
      await axios.post(url + 'simpan-siswa-pilihan-ekskul', {
        siswa: selectedNis,
        id_ekstra: id_ekstra,
        rombel: nama_rombel
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Data berhasil disimpan');
      await fetchSiswa()
      setModalIsOpen(false);
    } catch (error) {
      toast.error('Gagal menyimpan data');
    } finally {
      setLoadingSave(false);

    }
  };


  const handleSaveNilai = async () => {
    setLoadingSave(true)
    const token = localStorage.getItem('token')
    try {
      const filteredSiswa = siswa.filter(item => item.pilihanSiswa && item.pilihanSiswa.nama_ekstra === ekskul.nama_ekstra);

      if (filteredSiswa.length === 0) {
        toast.error('Tidak ada siswa. Tambahkan siswa terlebih dahulu!');
        setLoadingSave(false);
        return;
      }



      for (const item of filteredSiswa) {
        const nis = item.nis;
        // console.log(`Saving data for NIS: ${nis}, Nilai: ${nilai[nis]}`)
        await axios.post(url + 'simpan-nilai-ekskul', {
          nis: nis,
          nilai: nilai[nis],
          id_ekstra: id_ekstra,
          rombel: nama_rombel
        }, {
          headers: { Authorization: `Bearer ${token}` }
        })
      }
      toast.success('Data Nilai Ekstrakurikuler Berhasil Disimpan!')
      // Fetch updated nilai
      const fetchAllNilai = async () => {
        const nilaiData = {}
        for (const item of siswa) {
          nilaiData[item.nis] = await fetchNilai(item.nis)
        }
        setNilai(nilaiData)
      }
      fetchAllNilai()

    } catch (error) {
      toast.error('Failed to save data!')
    }
    setLoadingSave(false)
  }


  const handleDelete = async (nis) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(url + 'del-siswa-pilihan', {
        nis: nis,
        id_ekstra: id_ekstra,
        rombel: nama_rombel
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Data berhasil dihapus');
      await fetchSiswa();
    } catch (error) {
      toast.error('Gagal menghapus data');
    }
  };


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {ekskul.nama_ekstra} - {nama_rombel}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="row">

                <div className="col-md-6 mb-3">
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    <button
                      className="btn btn-warning sm-btn"
                      type="button"
                      onClick={() => navigate(`/guru/ekstrakurikuler/${nama_rombel}`)} // Use navigate
                    >
                      Kembali
                    </button>

                    <button
                      className="btn btn-primary sm-btn"
                      type="button"
                      title=""
                      onClick={() => setModalIsOpen(true)}
                    >
                      Tambah Siswa
                    </button>
                  </div>
                </div>
                <div className="col-md-3"></div>




                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NIS</th>
                        <th>Nama Siswa</th>
                        <th>Nilai / Keterangan
                          <input
                            type="text"
                            className="form-control sm-input"
                            placeholder="Nilai / Keterangan"
                            style={{ padding: '5px', height: '30px' }}
                            value={headerInput}
                            onChange={handleHeaderInputChange}
                          />
                        </th>
                        <th>Action</th>

                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          </td>
                        </tr>
                      ) : (
                        siswa
                          .filter(item => item.pilihanSiswa && item.pilihanSiswa.nama_ekstra === ekskul.nama_ekstra)
                          .map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.nis}</td>
                              <td>{item.nama}</td>
                              <td>
                                {loadingNilai ? <Spinner animation="border" size="sm" /> :
                                  <input
                                    type="text"
                                    className="form-control sm-input"
                                    placeholder="Nilai / Keterangan"
                                    style={{ padding: '5px', height: '30px' }}
                                    value={nilai[item.nis] || ''}
                                    onChange={(e) => setNilai({ ...nilai, [item.nis]: e.target.value })}
                                  />
                                }
                              </td>
                              <td> <button className='btn btn-danger sm-btn' onClick={() => handleDelete(item.nis)}>Delete</button></td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                  <button className="btn btn-primary mt-3" onClick={handleSaveNilai} disabled={loadingSave}>
                    {loadingSave ? <><Spinner animation="border" size="sm" /> Loading...</> : 'Simpan'}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalIsOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Pilih Siswa {nama_rombel} - {ekskul.nama_ekstra}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-styled mb-0">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                </th>
                <th>NIS</th>
                <th>Nama Siswa</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : (
                siswa.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.isChecked || false}
                        onChange={() => handleCheckboxChange(item.nis)}
                      />
                    </td>
                    <td>{item.nis}</td>
                    <td>{item.nama}</td>
                    <td>
                      {item.pilihanSiswa === null ? 'Belum Ikut Ekstra Pilihan' : item.pilihanSiswa.nama_ekstra}
                    </td>
                  </tr>
                ))
              )}

            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>

    </div >
  )
}

export default InputEkstraPilihan
