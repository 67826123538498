import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';
import 'datatables.net';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Siswa = ({ content, url }) => {
  const [siswa, setSiswa] = useState([]);
  const [tp, setTp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [selectedSiswa, setSelectedSiswa] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true)
  const [loadingUpload, setLoadingUpload] = useState(true)


  const [formState, setFormState] = useState({
    nis: '',
    nisn: '',
    nama: '',
    jk: '',
    agama: '',
    no_absen: ''
  });


  const tableRef = useRef(null);

  const fetchSiswa = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "siswa", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSiswa(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTpAktif = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "tahun-pelajaran-aktif", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTp(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSiswa();
    fetchTpAktif();
  }, []);

  useEffect(() => {
    if (!isLoading && siswa.length > 0 && !tableRef.current) {
      tableRef.current = $('#siswa').DataTable({
        pageLength: 10 // Set the number of entries per page to 10
      });
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.destroy();
        tableRef.current = null;
      }
    };
  }, [isLoading, siswa]);

  const handleEdit = (siswa) => {
    setSelectedSiswa(siswa);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedSiswa(null);
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    console.log("Selected Siswa:", selectedSiswa.nis);
    try {
      const response = await axios.post(`${url}updsiswa`, formState, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setShowModal(false);
      toast.success("Siswa updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to update siswa");
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (selectedSiswa) {
      setFormState({
        nis: selectedSiswa.nis,
        nisn: selectedSiswa.nisn,
        nama: selectedSiswa.nama,
        jk: selectedSiswa.jk,
        agama: selectedSiswa.agama,
        no_absen: selectedSiswa.no_absen
      });
    }
  }, [selectedSiswa]);

  const getStatus = (siswa) => {
    if (siswa.tp_id < tp.id) {
      if (siswa.rombel_nama.substring(0, 2) === '10' || siswa.rombel_nama.substring(0, 2) === '11') {
        return <span className="badge badge-danger">Belum dinaikkan</span>;
      } else if (siswa.rombel_nama.substring(0, 2) === '12') {
        return <span className="badge badge-danger">Belum diluluskan</span>;
      }
    } else if (siswa.tp_id === tp.id) {
      return <span className="badge badge-success">{siswa.rombel_nama}</span>;
    }
    return <span className="badge badge-success">{siswa.rombel_nama}</span>;
  };


  const openModalImport = () => {
    setShowModalImport(true);
  };

  const handleCloseModalImport = () => {
    setShowModalImport(false)
  }

  // Download Template
  const handleDownloadTemplate = async (e) => {
    e.preventDefault();
    console.log("Download Template button clicked"); // Tambahkan log ini
    const token = localStorage.getItem("token");

    console.log("URL:", url); // Tambahkan log ini

    try {
      const response = await axios({
        url: url + "template-import-siswa",
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "Template Import Data Siswa.xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url_ = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url_;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);

    }
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };


  // Proses Upload Template
  const uploadTemplate = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);

    try {
      setShowModalImport(false);
      await axios.post(url + "import-siswa", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Template Siswa Berhasil Diupload!");
      window.location.reload();

    } catch (error) {
      // console.error("Error uploading file:", error);
      // alert("Error uploading file.");
      toast.error("Error " + error.response.data.error);

    } finally {
      setLoadingUpload(false);

    }
  };




  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="col-md-3 mt-3 px-2">
              <button className='btn btn-primary sm-btn' onClick={openModalImport}>Import</button>
            </div>
            <div className="card-body">
              <div className="card table-card">
                <div style={{ overflowX: 'auto' }}>
                  <table id="siswa" className="table table-striped table-bordered dt-responsive nowrap">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>NIS</th>
                        <th>NISN</th>
                        <th>NAMA</th>
                        <th>AGAMA</th>
                        <th>JK</th>
                        <th>No Absen</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (

                        <tr>
                          <td colSpan="8" className="text-center">Loading...</td>
                        </tr>
                      ) : (

                        siswa
                          .sort((a, b) => a.rombel_nama === 'Alumni' ? 1 : b.rombel_nama === 'Alumni' ? -1 : 0)
                          .map((siswa, index) => (
                            <tr key={siswa.id}>
                              <td>{index + 1}</td>
                              <td>{siswa.nis}</td>
                              <td>{siswa.nisn}</td>
                              <td>{siswa.nama}</td>
                              <td>{siswa.agama}</td>
                              <td>{siswa.jk}</td>
                              <td>{siswa.no_abs}</td>
                              <td>{getStatus(siswa)}</td>
                              <td>
                                <Button className='btn btn-warning sm-btn' onClick={() => handleEdit(siswa)}>Edit</Button>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Siswa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSaveChanges}>
            <div className="form-group">
              <label>NIS</label>
              <input
                type="text"
                className="form-control"
                name="nis"
                value={formState.nis}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>NISN</label>
              <input
                type="text"
                className="form-control"
                name="nisn"
                value={formState.nisn}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Nama</label>
              <input
                type="text"
                className="form-control"
                name="nama"
                value={formState.nama}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Jenis Kelamin</label>
              <input
                type="text"
                className="form-control"
                name="jk"
                value={formState.jk}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Agama</label>
              <input
                type="text"
                className="form-control"
                name="agama"
                value={formState.agama}
                onChange={handleInputChange}
              />
            </div>

            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </form>
        </Modal.Body>
      </Modal>


      <Modal show={showModalImport} onHide={handleCloseModalImport}>
        <Modal.Header closeButton>
          <Modal.Title>Import Data Siswa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning" className='px-3'>
            <Alert.Heading>Info</Alert.Heading>
            <p>
              Upload hanya untuk kelas 10 saja, untuk kelas 11 dan 12 menggunakan fitur kenaikan kelas
            </p>
          </Alert>

          <button className="btn btn-primary sm-btn" onClick={handleDownloadTemplate}>Download Template</button>

          <input
            type="file"
            onChange={handleFileChange}
            className="form-control mt-3"
          />

          <Button variant="primary" onClick={uploadTemplate}>
            Upload
          </Button>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Siswa;