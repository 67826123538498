import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';


const SetTanggal = ({ url, content }) => {

  const [setting, setSetting] = useState([])
  const [kepsek, setKepsek] = useState([])
  const [tempat, setTempat] = useState('')
  const [tgl, setTgl] = useState('')
  const [nm, setNm] = useState('')
  const [nip, setNip] = useState('')


  const fetchSetting = async () => {

    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "setting", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSetting(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKepsek = async () => {

    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "kepsek", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKepsek(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSetting();
    fetchKepsek();
  }, []);

  const handleSaveAturan = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    try {

      const response = await axios.post(url + "simpan-setting", {
        tempat: tempat,
        tanggal: tgl
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Data berhasil tersimpan')
      fetchSetting()
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveKepsek = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    try {

      const response = await axios.post(url + "edit-kepsek", {
        kepsek: nm,
        nip: nip
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Data berhasil tersimpan')
      fetchKepsek()
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (setting?.tgl_cetak) {
      setTgl(setting.tgl_cetak);
    }
    if (setting?.tempat_cetak) {
      setTempat(setting.tempat_cetak);
    }
  }, [setting]);

  useEffect(() => {
    if (kepsek?.kepsek) {
      setNm(kepsek.kepsek);
    }
    if (kepsek?.nip) {
      setNip(kepsek.nip);
    }

  }, [kepsek]);




  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>



          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSaveAturan}>
                    <div className="mt-3">
                      <p>Tempat Cetak</p>
                      <input
                        type='text'
                        className='form-control'
                        value={tempat}
                        onChange={(e) => setTempat(e.target.value)}
                      />
                    </div>
                    <div className="mt-3">
                      <p>Tanggal Cetak</p>
                      <input
                        type='text'
                        className='form-control'
                        value={tgl}
                        onChange={(e) => setTgl(e.target.value)}
                      />
                    </div>

                    <button className='btn btn-primary sm-btn mt-3' type='submit'>Simpan</button>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSaveKepsek}>
                    <div className="mt-3">
                      <p>Nama Kepala Sekolah</p>
                      <input
                        type='text'
                        className='form-control'
                        value={nm}
                        onChange={(e) => setNm(e.target.value)}
                      />
                    </div>
                    <div className="mt-3">
                      <p>NIP</p>
                      <input
                        type='text'
                        className='form-control'
                        value={nip}
                        onChange={(e) => setNip(e.target.value)}
                      />
                    </div>

                    <button className='btn btn-primary sm-btn mt-3' type='submit'>Simpan</button>
                  </form>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default SetTanggal
