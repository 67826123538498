import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const DetailRombel = ({ url, content }) => {
  const { nama_rombel } = useParams();
  const [rombel, setRombel] = useState([])
  const [siswa, setSiswa] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [noAbsenValues, setNoAbsenValues] = useState({});

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);



  const fetchSiswa = async () => {
    setIsLoading(true)
    const token = localStorage.getItem('token');
    try {
      const res = await axios.post(`${url}isi-rombel`, {
        rombel_nama: nama_rombel
      }, { headers: { 'Authorization': `Bearer ${token}` } });
      setSiswa(res.data.data);
    } catch (error) {
      toast.error("Failed to fetch data");
    }
    setIsLoading(false)
  };

  const handleSave = async () => {
    const updatedSiswa = siswa.map((item, index) => ({
      nis: item.nis,
      noAbsen: noAbsenValues[index] || String(index + 1).padStart(2, '0')
    }));

    // Implement save logic here, for example, sending updatedSiswa to the server
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${url}upd-absen`, {
        nama_rombel: nama_rombel,
        siswa: updatedSiswa
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      toast.success("Data saved successfully");
      fetchSiswa()
    } catch (error) {
      toast.error("Failed to save data");
    }
  };


  useEffect(() => {

    fetchSiswa();
  }, [])



  useEffect(() => {
    const fetchRombel = async () => {
      const token = localStorage.getItem('token');
      try {
        const res = await axios.post(`${url}rombel-by-name`, {
          nama_rombel: nama_rombel
        }, { headers: { 'Authorization': `Bearer ${token}` } });
        setRombel(res.data.data);
      } catch (error) {
        toast.error("Failed to fetch data");
      }
    };

    fetchRombel();
  }, [])

  const handleNoAbsenChange = (index, value) => {
    setNoAbsenValues(prevValues => ({
      ...prevValues,
      [index]: value
    }));
  };

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6">
                  <h5>Nama Rombel: {nama_rombel}</h5>
                  <h5>Kelas / Level: {rombel && rombel.level}</h5>
                  <h5>Jurusan: {rombel && rombel.jurusan}</h5>
                </div>
                <div className="col-md-6">
                  <h5>Tahun Ajaran: {rombel && rombel.thn_pel}</h5>
                  <h5>Wali Kelas: {rombel && rombel.name}</h5>
                </div>
              </div>
            </div>

            <div className="card-body">

              <div className="col-12 mb-3">
                <button className="btn btn-primary sm-btn" onClick={handleShow}>Edit</button>
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>NIS</th>
                        <th>NISN</th>
                        <th>NAMA</th>
                        <th>JK</th>
                        <th>AGAMA</th>
                        <th>NO ABSEN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? <tr><td colSpan={7} className='text-center'><div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div></td></tr> :
                        siswa.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.nis}</td>
                            <td>{item.nisn}</td>
                            <td>{item.nama}</td>
                            <td>{item.jk}</td>
                            <td>{item.agama}</td>
                            <td>{item.no_abs}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Data Siswa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-styled mb-0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>NIS</th>
                  <th>NISN</th>
                  <th>Nama</th>
                  <th>JK</th>
                  <th>Agama</th>
                  <th>No Absen</th>
                </tr>
              </thead>
              <tbody>
                {siswa
                  .sort((a, b) => a.nama.localeCompare(b.nama)) // Sort by nama
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.nis}</td>
                      <td>{item.nisn}</td>
                      <td>{item.nama}</td>
                      <td>{item.jk}</td>
                      <td>{item.agama}</td>
                      <td>
                        <input
                          type="text"
                          value={noAbsenValues[index] || String(index + 1).padStart(2, '0')} // Auto number starting from 01
                          onChange={(e) => handleNoAbsenChange(index, e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DetailRombel
