import React from 'react'

const Dashboard = () => {
  return (
    <div>

      <div className="row">
        <div className="colxl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title bold">Dashboard</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">Admin</li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6">

          <div className="card splash-info-card">
            <div className="card-body dd-flex align-items-center">
              <div className="icon-info" style={{ backgroundColor: '#f6b26b' }}>
                <i className="fas fa-users" ></i>
              </div>
              <div className="icon-info-text">
                <h5 className="splash-title">Jumlah Siswa</h5>
                <h4 className="splash-card-title">NaN</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6">
          <div className="card splash-info-card">
            <div className="card-body dd-flex align-items-center">
              <div className="icon-info" style={{ backgroundColor: '#f6b26b' }}>
                <i className="fas fa-users" ></i>
              </div>
              <div className="icon-info-text">
                <h5 className="splash-title">Jumlah Guru</h5>
                <h4 className="splash-card-title">NaN</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6">
          <div className="card splash-info-card">
            <div className="card-body dd-flex align-items-center">
              <div className="icon-info" style={{ backgroundColor: '#f6b26b' }}>
                <i className="fas fa-users" ></i>
              </div>
              <div className="icon-info-text">
                <h5 className="splash-title">Jumlah Rombel</h5>
                <h4 className="splash-card-title">NaN</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6">
          <div className="card splash-info-card">
            <div className="card-body dd-flex align-items-center">
              <div className="icon-info" style={{ backgroundColor: '#f6b26b' }}>
                <i className="fas fa-users" ></i>
              </div>
              <div className="icon-info-text">
                <h5 className="splash-title">Item Nilai</h5>
                <h4 className="splash-card-title">NaN</h4>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card chart-card">
            <div className="card-header">
              <h4>Newest Orders</h4>
            </div>
            <div className="card-body pb-4">
              <div className="chart-holder">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>
                          <div className="checkbox">
                            <input id="checkbox1" type="checkbox" />
                            <label for="checkbox1"></label>
                          </div>
                        </th>
                        <th>Order ID</th>
                        <th>Billing Name</th>
                        <th>Date</th>
                        <th>Total</th>
                        <th>Payment Status</th>
                        <th>Payment Method</th>
                        <th>View Details</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox2" type="checkbox" />
                            <label for="checkbox2"></label>
                          </div>
                        </td>
                        <td>#JH2033</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/1.jpg" alt=" " />
                            <span className="ml-2 ">Emily Arnold</span>
                          </span>
                        </td>
                        <td>22/06/2022</td>
                        <td>$600</td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">Pending</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fab fa-cc-paypal"></i>
                            <span className="ml-2">Paypal</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox3" type="checkbox" />
                            <label for="checkbox3"></label>
                          </div>
                        </td>
                        <td>#MK4433</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/4.jpg" alt=" " />
                            <span className="ml-2 ">Mark Doe</span>
                          </span>
                        </td>
                        <td>14/07/2022</td>
                        <td>$700</td>
                        <td>
                          <label className="mb-0 badge badge-success" title="" data-original-title="Pending">Success</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fab fa-cc-visa"></i>
                            <span className="ml-2">Visa</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option">
                            <ul>
                              <li>
                                <a href="javascript:void(0);"><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox4" type="checkbox" />
                            <label for="checkbox4"></label>
                          </div>
                        </td>
                        <td>#MD4578</td>
                        <td>
                          <span className="img-thumb">
                            <img src="assets/images/table/7.jpg" alt=" " />
                            <span className="ml-2 ">Mark Smith</span>
                          </span>
                        </td>
                        <td>28/08/2022</td>
                        <td>$800</td>
                        <td>
                          <label className="mb-0 badge badge-danger" title="" data-original-title="Pending">Cancel</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fas fa-credit-card"></i>
                            <span className="ml-2">Credit Card</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox5" type="checkbox" />
                            <label for="checkbox5"></label>
                          </div>
                        </td>
                        <td>#DD1048</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/1.jpg" alt=" " />
                            <span className="ml-2 ">Mike Wood</span>
                          </span>
                        </td>
                        <td>13/04/2022</td>
                        <td>$880</td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">Pending</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fab fa-cc-mastercard"></i>
                            <span className="ml-2">Mastercard</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox6" type="checkbox" />
                            <label for="checkbox6"></label>
                          </div>
                        </td>
                        <td>#JH2033</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/3.jpg" alt=" " />
                            <span className="ml-2 ">Emily Arnold</span>
                          </span>
                        </td>
                        <td>22/06/2022</td>
                        <td>$600</td>
                        <td>
                          <label className="mb-0 badge badge-success" title="" data-original-title="Pending">Success</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fab fa-cc-paypal"></i>
                            <span className="ml-2">Paypal</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox7" type="checkbox" />
                            <label for="checkbox7"></label>
                          </div>
                        </td>
                        <td>#MK4433</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/1.jpg" alt=" " />
                            <span className="ml-2 ">John Doe</span>
                          </span>
                        </td>
                        <td>14/07/2022</td>
                        <td>$700</td>
                        <td>
                          <label className="mb-0 badge badge-danger" title="" data-original-title="Pending">Cancel</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fab fa-cc-visa"></i>
                            <span className="ml-2">Visa</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox8" type="checkbox" />
                            <label for="checkbox8"></label>
                          </div>
                        </td>
                        <td>#MD4578</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/7.jpg" alt=" " />
                            <span className="ml-2 ">Mark Smith</span>
                          </span>
                        </td>
                        <td>28/08/2022</td>
                        <td>$800</td>
                        <td>
                          <label className="mb-0 badge badge-success" title="" data-original-title="Pending">Success</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fas fa-credit-card"></i>
                            <span className="ml-2">Credit Card</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="checkbox">
                            <input id="checkbox9" type="checkbox" />
                            <label for="checkbox9"></label>
                          </div>
                        </td>
                        <td>#DD1048</td>
                        <td>
                          <span className="img-thumb ">
                            <img src="assets/images/table/4.jpg" alt=" " />
                            <span className="ml-2 ">Mike Wood</span>
                          </span>
                        </td>
                        <td>13/04/2022</td>
                        <td>$880</td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">Pending</label>
                        </td>
                        <td>
                          <span className="img-thumb">
                            <i className="fab fa-cc-mastercard"></i>
                            <span className="ml-2">Mastercard</span>
                          </span>
                        </td>
                        <td>
                          <label className="mb-0 badge badge-primary" title="" data-original-title="Pending">View Detail</label>
                        </td>
                        <td className="relative">
                          <a className="action-btn " href="javascript:void(0); ">
                            <svg className="default-size " viewBox="0 0 341.333 341.333 ">
                              <g>
                                <g>
                                  <g>
                                    <path d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667 C128,66.24,147.093,85.333,170.667,85.333z "></path>
                                    <path d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 S194.24,128,170.667,128z "></path>
                                    <path d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667 C213.333,275.093,194.24,256,170.667,256z "></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div className="action-option ">
                            <ul>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-edit mr-2 "></i>Edit</a>
                              </li>
                              <li>
                                <a href="javascript:void(0); "><i className="far fa-trash-alt mr-2 "></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Dashboard
