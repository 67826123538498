import React from 'react'
import Layouts from './Layouts'
import LeggerDkn from '../components/wakel/LeggerDkn'

const Legger = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <LeggerDkn url={url} content={'Legger / DKN'} />
      </Layouts>
    </div>
  )
}

export default Legger
