import React from 'react'
import Layouts from './Layouts'
import TahunPelajaran from '../components/admin/TahunPelajaran'
import Semester from '../components/admin/Semester'
import SetKurikulum from '../components/admin/SetKurikulum'
import Mode from '../components/admin/Mode'

const Settings = ({ content, url }) => {
  return (
    <div>
      <Layouts url={url}>
        {content === 'Tahun Pelajaran' ? <TahunPelajaran content={content} url={url} /> : null}
        {content === 'Semester' ? <Semester content={content} url={url} /> : null}
        {content === 'Set Kurikulum' ? <SetKurikulum content={content} url={url} /> : null}
        {content === 'Mode Rapor' ? <Mode content={content} url={url} /> : null}
      </Layouts>
    </div>
  )
}

export default Settings
