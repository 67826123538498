import React from 'react'
import Layouts from './Layouts'
import AbsenSiswa from '../components/wakel/AbsenSiswa'

const Absensi = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <AbsenSiswa url={url} content={'Absensi Siswa'} />
      </Layouts>
    </div>
  )
}

export default Absensi
