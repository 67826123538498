import React, { useState, useEffect } from 'react'
import Header from '../components/templates/Header'
import Sidebar from '../components/templates/Sidebar'
import Footer from '../components/templates/Footer'
import axios from 'axios'

const Layouts = ({ children, url }) => {

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);



  return (
    <div className="page-wrapper">
      <Header user={user} url={url} />
      <Sidebar url={url} />
      <div className="page-wrapper">
        <div className="main-content">
          {children}
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layouts
