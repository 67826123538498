import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { Button, Modal, Form, Spinner } from 'react-bootstrap';



const InputEkstra = ({ url, content }) => {

  const { id_ekstra, nama_rombel } = useParams()
  const navigate = useNavigate()
  const [ekskul, setEkskul] = useState([])
  const [siswa, setSiswa] = useState([])
  const [loading, setLoading] = useState(false)
  const [nilai, setNilai] = useState([])
  const [headerInput, setHeaderInput] = useState('')
  const [loadingNilai, setLoadingNilai] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)


  const fetchEkskul = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'get-ekskul-by-id', {
      id: id_ekstra,
      rombel: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setEkskul(response.data.data)
  }

  const fetchSiswa = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'siswa-by-rombel', {
      rombel_nama: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setSiswa(response.data.data)
    setLoading(false)
  }

  const fetchNilai = async (nis) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'get-nilai-ekskul-by-siswa', {
      nis: nis,
      id_ekstra: id_ekstra,
      rombel: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = response.data.data;
    return data && data.eks_ket_nilai ? data.eks_ket_nilai : '';
  }

  const handleHeaderInputChange = (e) => {
    const value = e.target.value
    setHeaderInput(value)
    const updatedNilai = {}
    siswa.forEach(item => {
      updatedNilai[item.nis] = value
    })
    setNilai(updatedNilai)
  }



  useEffect(() => {
    fetchEkskul()
    fetchSiswa()
  }, [])

  useEffect(() => {
    const fetchAllNilai = async () => {
      setLoadingNilai(true)
      const nilaiData = {}
      for (const item of siswa) {
        nilaiData[item.nis] = await fetchNilai(item.nis)
      }
      setNilai(nilaiData)
      setLoadingNilai(false)
    }
    if (siswa.length > 0) {
      fetchAllNilai()
    }
  }, [siswa])

  const handleInputChange = (e, nis) => {
    setNilai({
      ...nilai,
      [nis]: e.target.value
    })
  }

  const handleSave = async () => {
    setLoadingSave(true)
    const token = localStorage.getItem('token')
    try {
      for (const nis in nilai) {
        // console.log(`Saving data for NIS: ${nis}, Nilai: ${nilai[nis]}`)
        await axios.post(url + 'simpan-nilai-ekskul', {
          nis: nis,
          nilai: nilai[nis],
          id_ekstra: id_ekstra,
          rombel: nama_rombel
        }, {
          headers: { Authorization: `Bearer ${token}` }
        })
      }
      toast.success('Data Nilai Ekstrakurikuler Berhasil Disimpan!')
      // Fetch updated nilai
      const fetchAllNilai = async () => {
        const nilaiData = {}
        for (const item of siswa) {
          nilaiData[item.nis] = await fetchNilai(item.nis)
        }
        setNilai(nilaiData)
      }
      fetchAllNilai()

    } catch (error) {
      toast.error('Failed to save data!')
    }
    setLoadingSave(false)
  }


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {ekskul.nama_ekstra} - {nama_rombel}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="row">

                <div className="col-md-6 mb-3">
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    <button
                      className="btn btn-warning sm-btn"
                      type="button"
                      onClick={() => navigate(`/guru/ekstrakurikuler/${nama_rombel}`)} // Use navigate
                    >
                      Kembali
                    </button>


                  </div>
                </div>
                <div className="col-md-3"></div>



                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NIS</th>
                        <th>Nama Siswa</th>
                        <th>Nilai / Keterangan
                          <input
                            type="text"
                            className="form-control sm-input"
                            placeholder="Nilai / Keterangan"
                            style={{ padding: '5px', height: '30px' }}
                            value={headerInput}
                            onChange={handleHeaderInputChange}
                          />
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {loading ? <tr><td colSpan={4} style={{ textAlign: 'center' }}>Loading...</td></tr> :
                        siswa.map((item, index) => (
                          <tr key={index}>
                            <td style={{ padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                            <td style={{ padding: '5px' }}>{item.nis}</td>
                            <td style={{ padding: '5px' }}>{item.nama}</td>
                            <td style={{ padding: '5px' }}>
                              {loadingNilai ? <Spinner animation="border" size="sm" /> :
                                <input
                                  type="text"
                                  className="form-control sm-input"
                                  placeholder="Nilai / Keterangan"
                                  style={{ padding: '5px', height: '30px' }}
                                  value={nilai[item.nis] || ''}
                                  onChange={(e) => handleInputChange(e, item.nis)}
                                />
                              }
                            </td>
                          </tr>
                        ))}


                    </tbody>
                  </table>
                  <button className="btn btn-primary mt-3" onClick={handleSave} disabled={loadingSave}>
                    {loadingSave ? <><Spinner animation="border" size="sm" /> Loading...</> : 'Simpan'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputEkstra
