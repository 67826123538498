import React from 'react'
import Layouts from './Layouts'

import InputEkstra from '../components/wakel/InputEkstra'

const InputNilai = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <InputEkstra url={url} content={'Daftar Nilai Ekstrakurikuler'} />
      </Layouts>
    </div>
  )
}

export default InputNilai
