import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const Mode = ({ content, url }) => {
  const [mode, setMode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMode = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}mode`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMode(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = async (e, id) => {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${url}mode-pts/${id}`, {
      tampil_pts: e.target.value,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success("Mode berhasil diubah");
    fetchMode();
  }

  const handleAktifkan = async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${url}mode-aktifkan/${id}`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success("Mode berhasil diaktifkan");
    fetchMode();
    window.location.reload();

  }


  useEffect(() => {
    fetchMode();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>Mode</th>
                        <th>Status</th>
                        <th>Lanjutkan Nilai STS/PTS</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? <tr><td colSpan="4" style={{ textAlign: 'center' }}>Loading...</td></tr> : mode.map((item) => (
                        <tr key={item.id}>
                          <td>{item.mode_ket}</td>
                          <td>
                            <span className={`badge ${item.mode_is_active === '1' ? 'badge-success' : 'badge-danger'}`}>
                              {item.mode_is_active === '1' ? 'Active' : 'Inactive'}
                            </span>
                          </td>
                          <td>
                            <select className="form-control form-control-sm" onChange={(e) => handleChange(e, item.id_mode)}>
                              <option value="1" selected={item.tampil_pts === '1'}>YA</option>
                              <option value="0" selected={item.tampil_pts === '0'}>Tidak</option>
                            </select>
                          </td>
                          <td>
                            {item.mode_is_active === '0' ? (
                              <button type="button" className="effect-btn btn btn-success mt-2 mr-2 sm-btn" onClick={() => handleAktifkan(item.id_mode)}>Aktifkan</button>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))}


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mode
