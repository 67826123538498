import React from 'react'
import Layouts from './Layouts'
import Ekskul from '../components/wakel/Ekskul'

const Ekstrakurikuler = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <Ekskul url={url} content={'Ekstrakurikuler'} />
      </Layouts>
    </div>
  )
}

export default Ekstrakurikuler
