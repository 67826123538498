import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';

const Semester = ({ content, url }) => {
  const [semester, setSemester] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fetchSemester = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "semester", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSemester(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleAktifkan = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(url + "semester/" + id, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      toast.success("Semester berhasil diaktifkan");
      fetchSemester();
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error("Semester gagal diaktifkan");
    }
  };


  useEffect(() => {
    fetchSemester();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>Semester</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="3" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        semester.map((semester) => (
                          <tr key={semester.id}>
                            <td>{semester.semester}</td>
                            <td>
                              {semester.status === 1 ? (
                                <span className="badge badge-success">Active</span>
                              ) : (
                                <span className="badge badge-danger">Inactive</span>
                              )}
                            </td>
                            <td>
                              {semester.status === 0 ? (
                                <button type="button" className="effect-btn btn btn-success mt-2 mr-2 sm-btn" onClick={() => handleAktifkan(semester.id)}>Aktifkan</button>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ))
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Semester
