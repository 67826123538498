import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LogOut,
  reset
} from "../../features/authSlice";
import axios from "axios";

const Header = ({ user }) => {
  const url = 'https://api.smkturen.web.id/api/'
  // const url = 'http://103.13.206.72/api/public/api/'
  // const url = 'http://api-erapor.cybermmc.my.id/api/'
  // const url = 'http://localhost:8000/api/'

  const [isToggled, setIsToggled] = useState(false);
  const [tp, setTp] = useState('');
  const [smt, setSmt] = useState('');
  const token = localStorage.getItem('token');
  const [isLoadingTp, setIsLoadingTp] = useState(false);
  const [isLoadingSmt, setIsLoadingSmt] = useState(false);
  const [isLoadingMode, setIsLoadingMode] = useState(false);
  const [mode, setMode] = useState('');



  const getTp = async () => {
    setIsLoadingTp(true);
    try {
      const response = await axios.get(`${url}tahun-pelajaran-aktif`, {
        headers: { Authorization: `Bearer ${token}` },

      });
      setTp(response.data.data.thn_pel);
      setIsLoadingTp(false);
    } catch (error) {
      console.log(error);
      navigate('/');
    }
  }

  const getSmt = async () => {

    setIsLoadingSmt(true);
    try {
      const response = await axios.get(`${url}semester-aktif`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSmt(response.data.data.semester);
      setIsLoadingSmt(false);
    } catch (error) {
      console.log(error);
      navigate('/');
    }
  }

  const getMode = async () => {

    setIsLoadingMode(true);
    try {
      const response = await axios.get(`${url}mode-aktif`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMode(response.data.data.mode_ket);
      setIsLoadingMode(false);
    } catch (error) {
      console.log(error);
      navigate('/');
    }
  }


  useEffect(() => {
    getTp();
    getSmt();
    getMode();
  }, []);


  const handleToggle = (e) => {
    e.stopPropagation();
    setIsToggled(!isToggled);
    document.body.classList.toggle('mini-sidebar');
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(LogOut());
    dispatch(reset());
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    navigate("/");
  };


  return (
    <div>
      <header className="header-wrapper main-header">
        <div className="header-inner-wrapper">
          <div className="header-right">
            <div className="serch-wrapper">
              <form>
                <input type="text" placeholder="Search Here..." />
              </form>
              <a className="search-close" href="javascript:void(0);"><span className="icofont-close-line"></span></a>
            </div>
            <div className="header-left">
              <div className="header-links">
                <a href="#" className={`toggle-btn ${isToggled ? 'checked' : ''}`} onClick={handleToggle}>
                  <span></span>
                </a>
              </div>

              <button className="btn btn-warning sm-btn" >
                {isLoadingTp || isLoadingSmt || isLoadingMode ? <p style={{ color: 'black' }}>Loading...</p> : <p style={{ color: 'black' }}> Tp: {tp && tp} || Smt: {smt && smt} || Mode : {mode && mode}</p>}

              </button>
            </div>
            <div className="header-controls">
              <div className="setting-wrapper header-links">
                <a href="javascript:void(0);" className="setting-info">
                  <span className="header-icon">

                  </span>
                </a>
              </div>
              <div className="cart-wrapper header-links">


              </div>

              <div className="notification-wrapper header-links">

              </div>
              <div className="user-info-wrapper header-links">
                <a href="javascript:void(0);" className="user-info">
                  <img src="/assets/images/user.jpg" alt="" className="user-img" />
                  <div className="blink-animation">
                    <span className="blink-circle"></span>
                    <span className="main-circle"></span>
                  </div>
                </a>
                <div className="user-info-box">
                  <div className="drop-down-header">
                    <p>{user && user.name}</p>
                    <p>{user && user.email}</p>
                  </div>
                  <ul>
                    {/* <li>
                      <a href="profile.html">
                        <i className="far fa-edit"></i> Edit Profile
                      </a>
                    </li>
                    <li>
                      <a href="setting.html">
                        <i className="fas fa-cog"></i> Settings
                      </a>
                    </li> */}
                    <li>
                      <a onClick={handleLogout}>
                        <i className="fas fa-sign-out-alt"></i> logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
