import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';



const SetKKM = ({ url, content }) => {

  const [tpAktif, setTpAktif] = useState([])
  const [semester, setSemester] = useState([])
  const [kkm, setKkm] = useState([])
  const [level, setLevel] = useState([])
  const [kkmDetails, setKkmDetails] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [kkmId, setKkmId] = useState([])
  const [levelId, setLevelId] = useState([])
  const [selectedKkm, setSelectedKkm] = useState([])
  const [loading, setLoading] = useState(true)


  const fetchTpAktif = async () => {
    const token = localStorage.getItem('token')
    try {
      const res = await axios.get(`${url}tahun-pelajaran-aktif`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setTpAktif(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSemester = async () => {
    const token = localStorage.getItem('token')
    try {
      const res = await axios.get(`${url}semester-aktif`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setSemester(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchKkm = async () => {
    const token = localStorage.getItem('token')
    try {
      const res = await axios.get(`${url}kkm-by-tp-smt`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setKkm(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLevel = async () => {
    const token = localStorage.getItem('token')
    try {
      const res = await axios.get(`${url}level`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setLevel(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchKkmDetail = async (kkmId) => {
    const token = localStorage.getItem('token')
    try {
      const res = await axios.post(`${url}kkm-detail`, {
        kkm: kkmId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setKkmDetails(prevDetails => ({
        ...prevDetails,
        [kkmId]: res.data.data
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchKkmId = async (kkmId) => {
    const token = localStorage.getItem('token')
    try {
      const res = await axios.get(`${url}kkm-detail`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setKkmId(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(levelId, selectedKkm)
    const token = localStorage.getItem('token');
    try {
      const res = await axios.post(`${url}set-kkm`, {
        level: levelId,
        kkm: selectedKkm
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      toast.success('Berhasil menambahkan KKM')
      fetchKkm();
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchTpAktif()
    fetchSemester()
    fetchKkm()
    fetchLevel()
    fetchKkmId()
  }, [])

  useEffect(() => {
    if (kkm.length > 0) {
      kkm.forEach(item => {
        fetchKkmDetail(item.kkm_id)
      })
    }
  }, [kkm])

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header pb-0">
              {loading ? <Skeleton width="30%" /> : (
                <h5>KKM Tahun Pelajaran : {tpAktif.thn_pel} {semester.semester}</h5>

              )}
            </div>

            <Alert variant="warning" className='px-3'>
              <Alert.Heading>Info</Alert.Heading>
              <p>
                Pastikan saat merubah KKM belum ada Guru yang telah mengupload nilai.
              </p>
            </Alert>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <button className="btn btn-primary sm-btn" onClick={handleShow}>Tambah / Edit</button>
                </div>
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Level / Kelas</th>
                        <th>KKM</th>
                        <th>Predikat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="4" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        kkm.length === 0 ? (
                          <tr>
                            <td colSpan="4" className="text-center">Data tidak ditemukan</td>
                          </tr>
                        ) : (
                          kkm.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.level_id}</td>
                              <td>{item.kkm_id}</td>
                              <td>
                                {kkmDetails[item.kkm_id] ? (
                                  <table className="table table-sm table-hover table-striped">
                                    <tbody>
                                      <tr>
                                        <td>A +</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].a_plus}</td>
                                        <td>-</td>
                                        <td>100</td>
                                      </tr>
                                      <tr>
                                        <td>A</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].a}</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].a_plus - 1}</td>
                                      </tr>
                                      <tr>
                                        <td>A -</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].a_min}</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].a - 1}</td>
                                      </tr>
                                      <tr>
                                        <td>B +</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].b_plus}</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].a_min - 1}</td>
                                      </tr>
                                      <tr>
                                        <td>B</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].b}</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].b_plus - 1}</td>
                                      </tr>
                                      <tr>
                                        <td>B -</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].b_min}</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].b - 1}</td>
                                      </tr>
                                      <tr>
                                        <td>C</td>
                                        <td>=</td>
                                        <td>{kkmDetails[item.kkm_id][0].c}</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].b_min - 1}</td>
                                      </tr>
                                      <tr>
                                        <td>D</td>
                                        <td>=</td>
                                        <td>0</td>
                                        <td>-</td>
                                        <td>{kkmDetails[item.kkm_id][0].c - 1}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                ) : (
                                  <p>Loading...</p>
                                )}
                              </td>
                            </tr>
                          ))
                        )
                      )}


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah / Edit KKM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="level">
              <Form.Label>Level</Form.Label>
              <Form.Control as="select" custom onChange={(e) => setLevelId(e.target.value)}>
                <option value="">Pilih Level</option>
                {level.filter(item => item.id !== 4).map((item, index) => (
                  <option key={index} value={item.level}>{item.level}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className='mt-3'>
              <Form.Label >Pilih KKM</Form.Label>
              <Form.Control as="select" custom onChange={(e) => setSelectedKkm(e.target.value)}>
                <option value="">Pilih KKM</option>
                {kkmId.map((item, index) => (
                  <option key={index} value={item.kkm}>{item.kkm}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>Close</button>
          <button className="btn btn-primary" onClick={handleSubmit} type="submit">Save changes</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SetKKM
