import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';

const ReffKelompok = ({ url, content }) => {
  const [showModal, setShowModal] = useState(false);
  const [kelompok, setKelompok] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [huruf, setHuruf] = useState('')
  const [namaKelompok, setNamaKelompok] = useState('')


  const fetchKelompok = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "kelompok-mapel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKelompok(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchKelompok();
  }, []);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-kelompok-mapel", {
        huruf,
        nama_kelompok: namaKelompok
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success("Data berhasil disimpan!");
      fetchKelompok(); // Refresh the data
      handleClose(); // Close the modal
    } catch (error) {
      toast.error("Gagal menyimpan data!");
      console.log(error);
    }
  };



  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                <button className="btn btn-primary sm-btn" type="button" title="" onClick={handleShow}>Tambah</button>
              </div>
              <div className="row">



                <div className="table-responsive mt-3">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>Huruf</th>
                        <th>Kelompok</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="2" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        kelompok.map((item) => (
                          <tr key={item.id}>
                            <td>{item.huruf}</td>
                            <td>{item.nama_kelompok}</td>
                          </tr>
                        ))
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3">
            <p>Masukkan Huruf</p>
            <input
              type='text'
              className='form-control'
              value={huruf}
              onChange={(e) => setHuruf(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <p>Masukkan Nama Kelompok</p>
            <input
              type='text'
              className='form-control'
              value={namaKelompok}
              onChange={(e) => setNamaKelompok(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ReffKelompok
