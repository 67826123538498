import React from 'react'
import Layouts from './Layouts'
import Kenaikan from '../components/naik/Kenaikan'

const KenaikanKelas = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <Kenaikan url={url} content={'Kenaikan Kelas'} />
      </Layouts>
    </div>
  )
}

export default KenaikanKelas
