import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';

const TahunPelajaran = ({ content, url }) => {

  const [tahunPelajaran, setTahunPelajaran] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [newTahun, setNewTahun] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [latestTahun, setLatestTahun] = useState('');



  const fetchTahunPelajaran = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "tahun-pelajaran", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTahunPelajaran(response.data.data);
      const latest = Math.max(...response.data.data.map(tahun => parseInt(tahun.thn_pel.split('/')[0])));
      setLatestTahun(latest);
      setNewTahun(`${latest + 1}/${latest + 2}`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleAktifkan = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(url + "tahun-pelajaran/" + id, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      toast.success("Tahun Pelajaran berhasil diaktifkan");
      fetchTahunPelajaran();
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error("Tahun Pelajaran gagal diaktifkan");
    }
  };

  const handleTambahTahun = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "tahun-pelajaran",
        {
          thn_pel: newTahun,
          status: 0
        }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      toast.success("Tahun Pelajaran berhasil ditambahkan");
      fetchTahunPelajaran();
    } catch (error) {
      console.log(error);
      toast.error("Tahun Pelajaran gagal ditambahkan");
    }
  };


  useEffect(() => {
    fetchTahunPelajaran();
  }, []);


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="col-md-3 mt-3 px-2">
              <Button className="mb-3" onClick={() => setShowModal(true)}>Tambah Tahun Pelajaran</Button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>Tahun Pelajaran</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="3" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        tahunPelajaran.map((tahun) => (
                          <tr key={tahun.id}>
                            <td>{tahun.thn_pel}</td>
                            <td>
                              {tahun.status === 1 ? (
                                <span className="badge badge-success">Active</span>
                              ) : (
                                <span className="badge badge-danger">Inactive</span>
                              )}
                            </td>
                            <td>
                              {tahun.status === 0 ? (
                                <button type="button" className="effect-btn btn btn-success mt-2 mr-2 sm-btn" onClick={() => handleAktifkan(tahun.id)}>Aktifkan</button>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Tahun Pelajaran</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="tahunPelajaran">
              <Form.Label>Tahun Pelajaran</Form.Label>
              <Form.Control type="text" value={newTahun} onChange={(e) => setNewTahun(e.target.value)} readOnly />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleTambahTahun}>Tambah</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TahunPelajaran
