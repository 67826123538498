import React from 'react'
import Layouts from './Layouts'
import IsiPenilaian from '../components/guru/IsiPenilaian'

const Penilaian = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <IsiPenilaian url={url} content={'Penilaian'} />
      </Layouts>
    </div>
  )
}

export default Penilaian
