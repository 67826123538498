import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { NavLink, useParams } from 'react-router-dom'

const DaftarNilaiGuru = ({ url, content }) => {
  const { kode_mp, nama_rombel, kurikulum } = useParams()
  const [curr, setCurr] = useState([])
  const [level, setLevel] = useState('')
  const [siswa, setSiswa] = useState([])
  const [nilai, setNilai] = useState({})
  const [kkm, setKkm] = useState([])
  const [loadingNilai, setLoadingNilai] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [statusKirim, setStatusKirim] = useState(false)
  const [statusLock, setStatusLock] = useState([])

  const [siswNon, setSiswaNon] = useState([])


  const fetchCurr = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}curr-by-level`, {
        level: level
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setCurr(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }


  const fetchSiswa = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}siswa-by-rombel`, {
        rombel_nama: nama_rombel
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setSiswa(response.data.data);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSiswaNon = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(url + 'siswaNon', {
        rombel: nama_rombel,
        kdagm: localStorage.getItem('kdagm')
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setSiswa(response.data.data)

    } catch (error) {
      console.log(error)
    }
  }





  const fetchNilai = async (nis) => {
    const token = localStorage.getItem('token')
    setLoadingNilai(prevState => ({ ...prevState, [nis]: true }))
    try {
      const response = await axios.post(`${url}get-nilai`, {
        nis: nis,
        kdmp: kode_mp,
        rombel: nama_rombel,
        kurikulum: kurikulum,
        is_agama: localStorage.getItem('kdagm')
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setNilai(prevState => ({ ...prevState, [nis]: response.data.data }))
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingNilai(prevState => ({ ...prevState, [nis]: false }))
    }
  }







  useEffect(() => {
    if (nama_rombel) {
      let text = `${nama_rombel}`;
      let result = text.substr(0, 2);
      if (result === '10') {
        setLevel('1')
      } else if (result === '11') {
        setLevel('2')
      } else if (result === '12') {
        setLevel('3')
      }
    }

    if (kode_mp === 'PAG_1') {
      fetchSiswaNon();
    } else {
      fetchSiswa();
    }

  }, [nama_rombel, kode_mp])

  useEffect(() => {
    if (level) {
      fetchCurr()
    }
  }, [level])


  useEffect(() => {
    siswa.forEach(item => {
      fetchNilai(item.nis)
    })
  }, [siswa])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = siswa.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(siswa.length / itemsPerPage);

  const handleKirimNilai = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}kirim-nilai`, {
        kdmp: kode_mp,
        rombel: nama_rombel,
        is_agama: localStorage.getItem('kdagm')

      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      toast.success('Kirim Nilai Berhasil')
      fetchStatusKirim()
    } catch (error) {
      console.log(error)
      toast.error('Kirim Nilai Gagal')
    }
  }
  const handleBatalKirim = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}batal-kirim-nilai`, {
        kdmp: kode_mp,
        rombel: nama_rombel,
        is_agama: localStorage.getItem('kdagm')
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      toast.success('Nilai Berhasil Dibatalkan')
      setStatusKirim(0)
      fetchStatusKirim()
    } catch (error) {
      console.log(error)
      toast.error('Batal Kirim Gagal')
    }
  }

  const fetchStatusKirim = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}get-status-kirim`, {
        kdmp: kode_mp,
        rombel: nama_rombel,
        is_agama: localStorage.getItem('kdagm')
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setStatusKirim(response.data.data.status_kirim)
    } catch (error) {
      console.log(error)
    }
  }


  const fetchStatusLock = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "status-lock", {
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setStatusLock(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchStatusKirim()
    fetchStatusLock()

  }, [])

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {nama_rombel} - {kode_mp}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard-guru"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              {curr.map((item, index) => (
                <div key={index}>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="btn-group btn-group-pill" role="group" aria-label="Basic example">



                      <NavLink
                        to={`/guru/upload-nilai/${kode_mp}/${nama_rombel}/${item.kurikulum}`}
                        className="btn btn-warning sm-btn"
                        onClick={(e) => {
                          if (statusLock === '1') {
                            e.preventDefault();
                            toast.error('Nilai sudah dikunci Wali Kelas');
                          }
                          if (statusKirim === '1') {
                            e.preventDefault();
                            toast.error('Tidak dapat input nilai, nilai sudah dikirim. Batalkan terlebih dahulu!');
                          }
                        }}
                      >
                        <p style={{ color: 'black' }}>Input Nilai</p>
                      </NavLink>

                      <button
                        className={`btn ${statusKirim === '1' ? 'btn-danger' : 'btn-success'} sm-btn`}
                        type="button"
                        onClick={() => {
                          if (statusLock === '1' && statusKirim === '1') {
                            toast.error('Nilai sudah dikunci Wali Kelas');
                          } else {
                            statusKirim === '1' ? handleBatalKirim() : handleKirimNilai();
                          }
                        }}
                        title=""
                        disabled={Object.values(loadingNilai).some(isLoading => isLoading)} // Disable if any nilai is loading
                      >
                        {Object.values(loadingNilai).some(isLoading => isLoading) ? 'Loading...' : (statusKirim === '1' ? 'Batal Kirim' : 'Kirim Nilai')}
                      </button>


                      {/* <button
                        className={`btn ${statusKirim === '1' ? 'btn-danger' : 'btn-success'} sm-btn`}
                        type="button"
                        onClick={statusKirim === '1' ? handleBatalKirim : handleKirimNilai}
                        title=""
                        disabled={Object.values(loadingNilai).some(isLoading => isLoading)} 
                      >
                        {Object.values(loadingNilai).some(isLoading => isLoading) ? 'Loading...' : (statusKirim === '1' ? 'Batal Kirim' : 'Kirim Nilai')}
                      </button> */}

                    </div>
                    <span>{item.kurikulum === '1' ? 'Type : K-13' : item.kurikulum === '2' ? 'Type : Kurikulum Merdeka' : item.kurikulum}</span>
                  </div>
                </div>
              ))}
            </div>

            <div className="card-body">
              <div className="row">
                {curr.map((item, index) => (
                  <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '400px' }}>

                    <table className="table table-styled mb-0">

                      <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>

                        <tr>
                          <th>#</th>
                          <th>NIS</th>
                          <th>NISN</th>
                          <th>Nama Siswa</th>
                          {item.kurikulum === '2' ? (
                            <>
                              <th>Nilai</th>
                              <th style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Capaian Kompetensi</th>
                            </>
                          ) : (
                            <>
                              <th>Pengetahuan</th>
                              <th>Keterampilan</th>
                              <th>Nilai Akhir</th>
                              <th>Predikat</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems
                          .sort((a, b) => a.no_abs - b.no_abs) // Sort by no_abs
                          .map((siswaItem, siswaIndex) => (
                            <tr key={siswaIndex}>
                              <td>{indexOfFirstItem + siswaIndex + 1}</td>
                              <td>{siswaItem.nis}</td>
                              <td>{siswaItem.nisn}</td>
                              <td style={{ maxWidth: '150px' }}>{siswaItem.nama}</td>
                              {item.kurikulum === '2' ? (
                                <>
                                  {/* Isi Kurmer */}
                                  <td style={{ maxWidth: '80px' }}>
                                    {loadingNilai[siswaItem.nis] ? 'Loading...' : (nilai[siswaItem.nis] && Object.keys(nilai[siswaItem.nis]).length > 0 ? nilai[siswaItem.nis].olah_na : '')}
                                  </td>
                                  <td style={{ maxWidth: '300px', wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign: 'justify' }}>
                                    {loadingNilai[siswaItem.nis] ? 'Loading...' : (nilai[siswaItem.nis] && Object.keys(nilai[siswaItem.nis]).length > 0 ? (
                                      nilai[siswaItem.nis].capaian !== '' ? (
                                        <div className="alert alert-success" style={{ fontSize: '11px', display: 'block' }} dangerouslySetInnerHTML={{ __html: nilai[siswaItem.nis].capaian }} />
                                      ) : (
                                        <div className="alert alert-danger" style={{ fontSize: '11px', display: 'block' }} dangerouslySetInnerHTML={{ __html: nilai[siswaItem.nis].capaian_min }} />
                                      )
                                    ) : '')}
                                  </td>
                                </>
                              ) : (
                                <>
                                  {/* Isi K-13 */}
                                  <td style={{ maxWidth: '80px' }}>
                                    {loadingNilai[siswaItem.nis] ? 'Loading...' : (nilai[siswaItem.nis] && Object.keys(nilai[siswaItem.nis]).length > 0 ? nilai[siswaItem.nis].olah_p : '')}
                                  </td>
                                  <td style={{ maxWidth: '80px' }}>
                                    {loadingNilai[siswaItem.nis] ? 'Loading...' : (nilai[siswaItem.nis] && Object.keys(nilai[siswaItem.nis]).length > 0 ? nilai[siswaItem.nis].olah_k : '')}
                                  </td>
                                  <td style={{ maxWidth: '80px' }}>
                                    {loadingNilai[siswaItem.nis] ? 'Loading...' : (nilai[siswaItem.nis] && Object.keys(nilai[siswaItem.nis]).length > 0 ? nilai[siswaItem.nis].olah_na : '')}
                                  </td>
                                  <td style={{ maxWidth: '80px' }}>
                                    {loadingNilai[siswaItem.nis] ? 'Loading...' : (nilai[siswaItem.nis] && Object.keys(nilai[siswaItem.nis]).length > 0 ? nilai[siswaItem.nis].predikat : '')}
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="card-body">
                      <div className="d-flex justify-content-end">
                        <div className="fb-pagination">
                          <ul>
                            <li>
                              <a href="javascript:;" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                              </a>
                            </li>
                            {Array.from({ length: totalPages }, (_, index) => {
                              const pageNumber = index + 1;
                              return (
                                <li key={pageNumber}>
                                  <a
                                    href="javascript:;"
                                    className={currentPage === pageNumber ? "active" : ""}
                                    onClick={() => setCurrentPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            })}
                            <li>
                              <a href="javascript:;" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                          <div className="record-info">
                            <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, siswa.length)} of {siswa.length} records</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DaftarNilaiGuru
