import React from 'react'

const PindahKls = ({ url, content }) => {
  return (
    <div>
      <p>{content}</p>
    </div>
  )
}

export default PindahKls
