import React from 'react'
import Layouts from './Layouts'
import RiwayatAkademik from '../components/admin/RiwayatAkademik'

const Riwayat = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <RiwayatAkademik url={url} content={'Riwayat Akademik'} />
      </Layouts>
    </div>
  )
}

export default Riwayat
