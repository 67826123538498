import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMe,
  LogOut,
  reset
} from "../../features/authSlice";
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';




const Dashboard = ({ url }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const [guru, setGuru] = useState(null);
  const [ajar, setAjar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStatusKirim, setLoadingStatusKirim] = useState(false);
  const [wakel, setWakel] = useState([]);

  const fetchAjar = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.post(url + "guru-ajar", {
        guru_email: user && user.user.email
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAjar(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const fetchStatusKirim = async (mp_kode, rombel_nama) => {
    setLoadingStatusKirim(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-status-kirim", {
        kdmp: mp_kode,
        rombel: rombel_nama,
        is_agama: localStorage.getItem('kdagm')
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoadingStatusKirim(false);
      return response.data.data.status_kirim;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchWakel = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-wali-kelas", {
        wali_kelas: localStorage.getItem("email")
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWakel(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    setGuru(user && user.user);
    fetchAjar();
    fetchWakel();
  }, [user]);

  useEffect(() => {
    const updateStatusKirim = async () => {
      const updatedAjar = await Promise.all(ajar.map(async (item) => {
        const status = await fetchStatusKirim(item.mp_kode_, item.rombel_nama_);
        return { ...item, status };
      }));
      setAjar(updatedAjar);
    };

    if (ajar.length > 0 && ajar.some(item => item.status === undefined)) {
      updateStatusKirim();
    }
  }, [ajar]);


  return (
    <div>
      <div className="row">
        <div className="colxl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box splash-title-box-use">
              <h4 className="page-title">Dashboard</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard-guru"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">Guru</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">

          <div className="card overflow-hidden">
            <div className="card-body">
              <div className="text-center">
                <div className="card-bg-img">
                  <img src="assets/images/background.jpg" alt="" title="" />
                </div>
                <div className="profile-photo">
                  <img src="assets/images/kelas.png" className="rounded-circle" alt="" />
                </div>
                <h4 className="mt-4 mb-1">
                  {guru ? guru.name : <Skeleton width={100} />}
                </h4>
                <p className="text-muted">
                  {guru ? guru.email : <Skeleton width={150} />}
                </p>

              </div>
            </div>
            {wakel && (
              <div className="card-footer counter-holder pt-0 pb-0 text-center border-top">
                <div className="row counter-holder">
                  <div className="pt-3 pb-3">
                    <h3 className="mb-1">
                      <span className="count-no" data-count="150">
                        {isLoading ? <Skeleton width={100} /> : wakel.nama_rombel}
                      </span>
                    </h3>
                    <span>WALI KELAS</span>
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>


        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
          <div className="card table-card">
            <div className="card-header pb-0">
              <h4>Rincian Kelas</h4>
            </div>
            <div className="card-body">
              <div className="chart-holder">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Kelas</th>
                        <th>Kode Mapel</th>
                        <th>Nama Mapel</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="5" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        ajar.length === 0 ? (
                          <tr>
                            <td colSpan="5" className="text-center">Tidak Ada Data</td>
                          </tr>
                        ) : (
                          ajar.map((ajar, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {/* <td>{ajar.rombel_nama_}</td> */}
                              <td>
                                <NavLink to={`/penilaian/${ajar.mp_kode_}/${ajar.rombel_nama_}`}>
                                  <p style={{ color: "#52a5ef" }}>{ajar.rombel_nama_}</p>
                                </NavLink>
                              </td>
                              <td>{ajar.mp_kode_}</td>
                              <td>
                                {ajar.mp_kode_ === 'PAG_1' ? 'Agama Non Islam' : ajar.nama_mp}
                                <span className="badge badge-info ml-2">
                                  {{
                                    '1': 'Kristen',
                                    '2': 'Katolik',
                                    '3': 'Hindu',
                                    '4': 'Budha',
                                    '5': 'Konghuchu',
                                    '6': 'Aliran Kepercayaan'
                                  }[user.user.agm_desk] || user.user.agm_desk}
                                </span>
                              </td>
                              <td>
                                {loadingStatusKirim ? (
                                  <span className="badge badge-warning">Loading...</span>
                                ) : ajar.status === '1' ? (
                                  <span className="badge badge-success">Sudah Dikirim</span>
                                ) : (
                                  <span className="badge badge-danger">Belum Dikirim</span>
                                )}
                              </td>
                            </tr>
                          ))
                        )
                      )}
                    </tbody>
                  </table>
                </div>


              </div>
            </div>
          </div>



        </div>


      </div>
    </div>
  )
}

export default Dashboard
