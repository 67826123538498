import React from 'react'
import Layouts from './Layouts'
import DknKelas from '../components/admin/DknKelas'
import DknJurusan from '../components/admin/DknJurusan'
import DknKakomli from '../components/admin/DknKakomli'

const DknPerKelas = ({ url, type }) => {
  return (
    <div>
      <Layouts url={url} >
        {type === 'kelas' ? (
          <DknKelas url={url} content={'DKN Per Kelas'} />
        ) : type === 'jurusan' ? (
          <DknJurusan url={url} content={'DKN Per Jurusan'} />
        ) : (
          <DknKakomli url={url} content={'DKN Jurusan'} />
        )}
      </Layouts>
    </div>
  )
}

export default DknPerKelas
