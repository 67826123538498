import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { Button, Modal, Form, Spinner } from 'react-bootstrap';



const AbsenSiswa = ({ url, content }) => {
  const { nama_rombel } = useParams()
  const [siswa, setSiswa] = useState([])
  const [loading, setLoading] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [file, setFile] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [refreshAbsensi, setRefreshAbsensi] = useState(false) // State to trigger absensi refresh

  const fetchSiswa = useCallback(async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(url + 'siswa-by-rombel', {
        rombel_nama: nama_rombel
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setSiswa(response.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [url, nama_rombel])

  const fetchAbsensi = useCallback(async (nis) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(url + 'get-absensi', {
        nis: nis
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response.data && response.data.data) {
        return response.data.data
      } else {
        return { sakit: 0, ijin: 0, alpa: 0 } // Default values if data is not found
      }
    } catch (error) {
      console.log(error)
      return { sakit: 0, ijin: 0, alpa: 0 } // Default values in case of error
    }
  }, [url])

  useEffect(() => {
    fetchSiswa()
  }, [fetchSiswa])

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        url: url + "template-absensi",
        method: "POST",
        data: {
          rombel: nama_rombel,
        },
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "Template Absensi - " + nama_rombel + ".xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url_ = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url_;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadTemplate = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("rombel", nama_rombel);

    try {
      setModalIsOpen(false);
      await axios.post(url + "import-absensi", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await fetchSiswa();
      setRefreshAbsensi(true); // Trigger absensi refresh
      toast.success("Template Absensi Berhasil Diupload!");
    } catch (error) {
      toast.error("Error " + error.response.data.error);
    } finally {
      setLoadingUpload(false);
    }
  };

  const memoizedSiswaRows = useMemo(() => {
    const sortedSiswa = [...siswa].sort((a, b) => a.no_abs - b.no_abs);
    return sortedSiswa.map((siswa, index) => (
      <SiswaRow key={index} index={index} siswa={siswa} fetchAbsensi={fetchAbsensi} refreshAbsensi={refreshAbsensi} setRefreshAbsensi={setRefreshAbsensi} />
    ));
  }, [siswa, fetchAbsensi, refreshAbsensi]);

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {nama_rombel}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    <button
                      className="btn btn-primary sm-btn"
                      type="button"
                      title=""
                      onClick={() => setModalIsOpen(true)}
                      disabled={loading}
                    >
                      Upload Data
                    </button>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2}>No Absen</th>
                        <th rowSpan={2}>NISN</th>
                        <th rowSpan={2}>NIS</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={3} className='text-center'>Ketidakhadiran</th>
                      </tr>
                      <tr>
                        <th className='text-center'>Sakit</th>
                        <th className='text-center'>Ijin</th>
                        <th className='text-center'>Alpa</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        memoizedSiswaRows
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalIsOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Data Absensi {nama_rombel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button className="btn btn-primary sm-btn" onClick={handleDownloadTemplate}>Download Template</button>
          <input
            type="file"
            onChange={handleFileChange}
            className="form-control mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadTemplate}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const SiswaRow = React.memo(({ index, siswa, fetchAbsensi, refreshAbsensi, setRefreshAbsensi }) => {
  const [absensi, setAbsensi] = useState({ sakit: 0, ijin: 0, alpa: 0 })
  const [loading, setLoading] = useState(true)
  const [refreshing, setRefreshing] = useState(false) // State to show loading spinner during refresh

  const fetchAndSetAbsensi = useCallback(async () => {
    setRefreshing(true)
    const data = await fetchAbsensi(siswa.nis)
    setAbsensi(data)
    setLoading(false)
    setRefreshing(false)
  }, [siswa.nis, fetchAbsensi])

  useEffect(() => {
    fetchAndSetAbsensi()
  }, [fetchAndSetAbsensi])

  useEffect(() => {
    if (refreshAbsensi) {
      fetchAndSetAbsensi().then(() => setRefreshAbsensi(false))
    }
  }, [refreshAbsensi, fetchAndSetAbsensi, setRefreshAbsensi])

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{siswa.no_abs}</td>
      <td>{siswa.nisn}</td>
      <td>{siswa.nis}</td>
      <td>{siswa.nama}</td>
      <td className='text-center'>{loading || refreshing ? <Spinner animation="border" size="sm" /> : absensi.sakit}</td>
      <td className='text-center'>{loading || refreshing ? <Spinner animation="border" size="sm" /> : absensi.ijin}</td>
      <td className='text-center'>{loading || refreshing ? <Spinner animation="border" size="sm" /> : absensi.alpa}</td>
    </tr>
  )
})

export default AbsenSiswa