import React from 'react'

const RiwayatAkademik = ({ url, content }) => {
  return (
    <div>
      <p>{content}</p>
    </div>
  )
}

export default RiwayatAkademik
