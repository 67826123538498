import React from 'react'
import Layouts from './Layouts'
import StatusNilai from '../components/wakel/StatusNilai'

const StatusNilaiWakel = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <StatusNilai url={url} content={'Status Nilai'} />
      </Layouts>
    </div>
  )
}

export default StatusNilaiWakel
