import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';



const Mapel = ({ content, url }) => {
  const [mapel, setMapel] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [kdmp, setKdmp] = useState('')
  const [namaMp, setNamaMp] = useState('')

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);



  const fetchMapel = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(url + "mapel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMapel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchMapel();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mapel.slice(indexOfFirstItem, indexOfLastItem);

  const handleAddMapel = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "add-mapel", {
        kode_mp: kdmp,
        nama_mp: namaMp,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setShowModal(false);
      if (response.data.status === true) {
        toast.success("Berhasil menambahkan mapel");
      } else {
        toast.error(response.data.message);
      }
      fetchMapel();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <Alert variant="warning" className='px-3'>
                <Alert.Heading>Info</Alert.Heading>
                <p>
                  Kode Mapel PAG_1 khusus untuk Agama Non Islam
                </p>
              </Alert>
            </div>

            <div className="card-body">

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    <button className="btn btn-primary sm-btn" type="button" title="" onClick={handleShowModal}>Tambah</button>
                  </div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 mb-3 d-flex justify-content-end">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Kode Mapel</th>
                        <th>Nama Mapel</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="4" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        currentItems
                          .filter((r) => r.nama_mp.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((r, index) => (
                            <tr key={index}>
                              <td>{indexOfFirstItem + index + 1}</td>
                              <td>{r.kode_mp}</td>
                              <td>{r.nama_mp}</td>

                              {/* <td>
                                <button className='btn btn-primary sm-btn'>Edit</button>
                                <button className='btn btn-danger sm-btn'>Hapus</button>
                              </td> */}
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                  <div className="pagination d-flex justify-content-end mt-3">
                    {Array.from({ length: Math.ceil(mapel.length / itemsPerPage) }, (_, i) => (
                      <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={`btn ${currentPage === i + 1 ? 'squer-btn btn-outline primary-outline sm-btn' : 'squer-btn btn-outline secondary-outline sm-btn'}`}
                      >
                        {i + 1}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Mapel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddMapel}>
            <div className="mt-3">
              <p>Masukkan Kode Mapel</p>
              <input type='text' className='form-control' value={kdmp} onChange={(e) => setKdmp(e.target.value)} required />
            </div>
            <div className="mt-3">
              <p>Masukkan Nama Mapel</p>
              <input type='text' className='form-control' value={namaMp} onChange={(e) => setNamaMp(e.target.value)} required />
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Mapel
