import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';

const SetTugasGuru = ({ url, content }) => {
  const [rombel, setRombel] = useState([])
  const [selectedRombel, setSelectedRombel] = useState('')
  const [template, setTemplate] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [guru, setGuru] = useState([])
  const [dataGuru, setDataGuru] = useState({})

  const fetchRombel = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchGuru = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "guru", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGuru(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTemplate = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true)
      const response = await axios.post(url + "get-list-mapel", {
        rombel: selectedRombel,
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setTemplate(response.data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGuruMengajar = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-guru-mengajar", {
        mp: mp,
        rombel: selectedRombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataGuru(prevState => ({
        ...prevState,
        [mp]: response.data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchRombel();
    fetchGuru()
  }, []);

  useEffect(() => {
    fetchTemplate();
  }, [selectedRombel]);

  useEffect(() => {
    template.forEach(item => {
      fetchGuruMengajar(item.mp);

    });
  }, [template]);

  const handleSubmit = async (email, mp) => {

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-tugas", {
        guru_email: email,
        mp_kode: mp,
        rombel_nama: selectedRombel,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Berhasil tambah tugas mengajar");
      fetchTemplate();
    } catch (error) {
      toast.error("Gagal tambah tugas mengajar");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <select className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                <option value="">Pilih Rombel</option>
                {rombel.map((r) => (
                  <option key={r.id} value={r.nama_rombel}>{r.nama_rombel}</option>
                ))}
              </select>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Mapel</th>
                        <th>Guru</th>

                      </tr>
                    </thead>
                    <tbody>
                      {selectedRombel === '' ? (
                        <tr><td colSpan="4" className="text-center">Pilih Rombel terlebih dahulu</td></tr>
                      ) : isLoading ? (
                        <tr><td colSpan="4" className="text-center"><Spinner animation="border" size="sm" /></td></tr>
                      ) : (
                        template.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.nama_mp}</td>
                            <td>
                              <Select
                                className='form-control'
                                options={guru.map(g => ({ value: g.email, label: g.name }))}
                                onChange={(selectedOption) => handleSubmit(selectedOption.value, item.mp)}
                                placeholder="Pilih Guru"
                                value={guru.find(g => g.email === dataGuru[item.mp]?.email) ? { value: dataGuru[item.mp]?.email, label: dataGuru[item.mp]?.name } : null}
                              />
                            </td>
                          </tr>
                        ))
                      )}


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetTugasGuru
