import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, Form } from 'react-bootstrap';


const Penilaian = ({ url, content }) => {

  const { user } = useSelector((state) => state.auth);
  const [guru, setGuru] = useState(null);
  const { kode_mp, nama_rombel } = useParams()

  const [mee, setMee] = useState([])
  const [rombel, setRombel] = useState([])
  const [mapel, setMapel] = useState([])
  const [siswa, setSiswa] = useState([])
  const [jmlSiswa, setJmlSiswa] = useState(0)
  const [tp, setTp] = useState([])
  const [semester, setSemester] = useState([])
  const [kkm, setKkm] = useState([])
  const [curr, setCurr] = useState([]);
  const [loading, setLoading] = useState(true)
  const [statusKirim, setStatusKirim] = useState([])
  const [nonIslamSiswaCount, setNonIslamSiswaCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [select, setSelect] = useState([])
  const [loadingSave, setLoadingSave] = useState(false)

  const [siswaNon, setSiswaNon] = useState([])
  const [countSiswaNon, setcountSiswaNon] = useState(0)





  const openModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchRombel = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'rombel-by-name', {
      nama_rombel: nama_rombel
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    setRombel(response.data.data)
    setLoading(false)
  }


  const fetchMe = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(url + "me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setMee(response)

    setLoading(false)
    await fetchRombel();
    await fetchSiswaNon();
  }

  const fetchMapel = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'mapel-by-kode', {
      kode_mp: kode_mp
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    setMapel(response.data.data)
    setLoading(false)
  }

  const fetchSiswa = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'siswa-by-rombel', {
      rombel_nama: nama_rombel
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    setSiswa(response.data.data)
    setJmlSiswa(response.data.data.length)

    // Calculate the number of students with agama !== 'I'
    const nonIslamSiswaCount = response.data.data.filter(siswa => siswa.agama !== 'I').length;
    setNonIslamSiswaCount(nonIslamSiswaCount);
  }

  const fetchTp = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(url + 'tahun-pelajaran-aktif', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    setTp(response.data.data)
    setLoading(false)
  }


  const fetchSiswaNon = async () => {
    const token = localStorage.getItem('token');
    try {
      console.log('kdagm:', mee?.data?.user?.agm_desk); // Log the value of kdagm
      const response = await axios.post(url + 'siswaNon', {
        rombel: nama_rombel,
        kdagm: localStorage.getItem('kdagm')
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSiswaNon(response.data.data);
      if (response.data.status === false) {
        setcountSiswaNon(0);
      }
      setcountSiswaNon(response.data.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSemester = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(url + 'semester-aktif', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    setSemester(response.data.data)
    setLoading(false)
  }

  const fetchKkm = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(url + 'kkm-by-level-thn-smt', {
        level: rombel && rombel.level,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setKkm(response.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getCurr = async () => {
    const token = localStorage.getItem("token");
    if (!rombel || !rombel.level_id) {
      console.log("Rombel or level_id is undefined");
      return;
    }
    try {
      const response = await axios.post(`${url}curr-by-level`, {
        level: rombel.level_id
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCurr(response.data.data);
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  };

  const fetchStatusKirim = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}get-status-kirim`, {
        kdmp: kode_mp,
        rombel: nama_rombel,
        is_agama: localStorage.getItem('kdagm')
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setStatusKirim(response.data.data.status_kirim)
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    setGuru(user && user.user);
  }, [user]);

  // useEffect(() => {
  //   fetchMe()
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchMe();
      await fetchRombel();
      await fetchMapel();
      await fetchSiswa();
      await fetchTp();
      await fetchSemester();
      await fetchStatusKirim();

    };

    fetchData();
  }, [nama_rombel]);



  useEffect(() => {
    getCurr()
    fetchKkm()

  }, [rombel])

  const handleNavLinkClick = (e) => {
    if (kkm.length === 0) {
      e.preventDefault();
      toast.error('KKM belum diset. Hubungi Admin!');
    } else if (mapel.kode_mp === 'PAG_1' && countSiswaNon === 0) {
      e.preventDefault();
      toast.error('Silahkan Tambahkan / Pilih Siswa terlebih dahulu');
    }
  };

  const handleCheckboxChange = (nis) => {
    const updatedSiswa = siswa.map(item =>
      item.nis === nis ? { ...item, isChecked: !item.isChecked } : item
    );
    setSiswa(updatedSiswa);
    setSelect(updatedSiswa.filter(item => item.isChecked));
  };

  const handleSave = async () => {
    setLoadingSave(true);
    const token = localStorage.getItem('token');
    const selectedNis = select.filter(item => item.isChecked).map(item => item.nis);

    if (selectedNis.length === 0) {
      toast.error('Tidak ada siswa yang dipilih');
      setLoadingSave(false);
      return;
    }

    try {
      console.log(selectedNis)
      await axios.post(url + 'simpan-agama', {
        agm_nis_siswa: selectedNis,
        guru_pengampu: localStorage.getItem('email'),
        agm_rombel: nama_rombel
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Data berhasil disimpan');
      await fetchSiswaNon()
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Gagal menyimpan data');
    } finally {
      setLoadingSave(false);
    }
  };





  return (
    <div>
      <div className="row">
        <ToastContainer />
        <div className="colxl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box splash-title-box-use">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard-guru"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">

          <div className="card overflow-hidden">
            <div className="card-body">
              <div className="text-center">
                <div className="card-bg-img">
                  <img src="/assets/images/background.jpg" alt="" title="" />
                </div>
                <div className="profile-photo">
                  <img src="/assets/images/kelas.png" className="rounded-circle" alt="" />
                </div>
                <h4 className="mt-4 mb-1">{loading ? <Skeleton width="100%" /> : nama_rombel}</h4>
                <p className="text-muted">Wali Kelas</p>
                <p className="text-muted">{loading ? <Skeleton width="100%" /> : rombel.name}</p>

              </div>
            </div>
            <div className="card-footer counter-holder pt-0 pb-0 text-center border-top">
              <div className="row counter-holder">
                <span>Status Nilai</span>
                <div className="pt-3 pb-3" style={{ color: statusKirim === '1' ? '#30b252' : 'red' }}>
                  <h3 className="mb-1">

                    <span className="count-no" data-count="150">
                      {loading ? <Skeleton width="100%" /> : (statusKirim === '1' ? 'Sudah Terkirim' : 'Belum Terkirim')}
                    </span>

                  </h3>


                </div>

              </div>
            </div>
          </div>

        </div>


        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
          <div className="card table-card">
            <div className="card-header pb-0">
              <h4>Detail Kelas {nama_rombel}</h4>
            </div>
            <div className="card-body">
              <div className="chart-holder">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <tbody>
                      <tr>
                        <td width="5%"> <i className="fas fa-book"></i></td>
                        <td width="40%" className="">Mata Pelajaran</td>
                        <td width="50%" className="">
                          {loading ? <Skeleton width="100%" /> : mapel.kode_mp === 'PAG_1' ? 'Agama Non Islam' : mapel.nama_mp}
                          {/* {console.log(mee)} */}
                          <span className="badge badge-info ml-2">
                            {{
                              '1': 'Kristen',
                              '2': 'Katolik',
                              '3': 'Hindu',
                              '4': 'Budha',
                              '5': 'Konghuchu',
                              '6': 'Aliran Kepercayaan'
                            }[mee?.data?.user?.agm_desk] || mee?.data?.user?.agm_desk}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td width="5%"> <i className="fas fa-user"></i></td>
                        <td width="40%" className="">Jumlah Siswa</td>
                        <td width="50%" className="">
                          {loading ? <Skeleton width="100%" /> : mapel.kode_mp === 'PAG_1' ? countSiswaNon : jmlSiswa}
                        </td>
                      </tr>
                      <tr>
                        <td width="5%"> <i className="fas fa-calendar"></i></td>
                        <td width="40%" className="">Tahun Pelajaran / Semester</td>
                        <td width="50%" className="">
                          {loading ? <Skeleton width="50%" /> : `${tp.thn_pel} / ${semester.semester}`}
                        </td>
                      </tr>
                      <tr>
                        <td width="5%"> <i className="fa fa-exclamation-triangle"></i></td>
                        <td width="40%" className="">KKM</td>
                        <td width="50%" className="">
                          {loading ? <Skeleton width="100%" /> : kkm.length > 0 ? kkm[0].kkm_id : <span className="badge badge-danger" style={{ color: 'black' }}>Belum Diset. Hubungi Admin!</span>}
                        </td>
                      </tr>
                      <tr>
                        <td width="5%"> <i className="fa fa-exclamation-triangle"></i></td>
                        <td width="40%" className="">Kurikulum</td>
                        <td width="50%" className="">
                          {/* {console.log(curr[0].kurikulum)} */}
                          {loading ? <Skeleton width="100%" /> : curr.length > 0 ?
                            (curr[0].kurikulum === '1' ? 'K13' : curr[0].kurikulum === '2' ? 'Kurikulum Merdeka' : curr[0].kurikulum)
                            : <span className="badge badge-danger" style={{ color: 'black' }}>Belum Diset. Hubungi Admin!</span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td width="5%"> <i className="fas fa-edit"></i></td>
                        <td width="40%" className="">Input Nilai</td>
                        <td width="50%" className="">
                          {loading ? <Skeleton width="100%" /> :
                            <NavLink
                              to={`/guru/input-nilai/${kode_mp}/${nama_rombel}/${curr.length > 0 ? curr[0].kurikulum : ''}`}
                              className="btn btn-warning sm-btn"
                              onClick={handleNavLinkClick}
                            >
                              <p style={{ color: 'black' }}>Input Nilai</p>
                            </NavLink>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>


              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">

            <div className="card-header d-flex justify-content-between align-items-center">
              <h4>List Siswa Kelas {nama_rombel}</h4>
              {mapel.kode_mp === 'PAG_1' && (
                <button className='btn btn-primary sm-btn' onClick={openModal}>Pilih Siswa</button>
              )}

            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>NIS</th>
                      <th>NISN</th>
                      <th>Nama Siswa</th>
                      <th>Jenis Kelamin</th>
                      <th>Agama</th>
                      <th>No Absen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          <Skeleton width="100%" />
                          <Skeleton width="100%" />
                          <Skeleton width="100%" />

                        </td>
                      </tr>
                    ) : siswa.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">Tidak ada data siswa</td>
                      </tr>
                    ) :
                      (mapel.kode_mp === 'PAG_1' ? (siswaNon || []) : (siswa || []))
                        .sort((a, b) => a.no_abs - b.no_abs) // Sort by no_abs
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.nis}</td>
                            <td>{item.nisn}</td>
                            <td>{item.nama}</td>
                            <td>{item.jk}</td>
                            <td>{item.agama}</td>
                            <td>{item.no_abs}</td>
                          </tr>
                        ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Pilih Siswa</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <table className="table table-styled mb-0">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                </th>
                <th>NIS</th>
                <th>NISN</th>
                <th>Nama Siswa</th>
                <th>Agama</th>
              </tr>
            </thead>
            <tbody>
              {siswa
                .filter(item => mapel.kode_mp !== 'PAG_1' || item.agama !== 'I')
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.isChecked || false}
                        onChange={() => handleCheckboxChange(item.nis)}
                      />
                    </td>
                    <td>{item.nis}</td>
                    <td>{item.nisn}</td>
                    <td>{item.nama}</td>
                    <td>{item.agama}</td>
                  </tr>
                ))}

            </tbody>
          </table>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>



    </div >
  )
}

export default Penilaian
