import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { LoginUser, reset } from "../../features/authSlice";
import './Login.css';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      if (user.user.role === '1') {
        navigate("/dashboard");
      } else if (user.user.role === '2') {
        navigate("/dashboard-guru");
      }
    }
    dispatch(reset());
  }, [user, isSuccess, navigate, dispatch]);


  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };

  return (
    <div className="splash-auth-wrapper">
      <div className="splash-auth-box">
        <div className="splash-auth-content">
          <form onSubmit={Auth}>
            <a href="/" className="splash-auth-logo">
              <img src="assets/images/tes.png" width={200} height={150} alt="Logo" />
            </a>
            <h2>Hello, Welcome to <span>E-RAPOR</span></h2>
            <p>SMK TUREN</p>

            {isError && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <strong>{message}</strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}


            <div className="splash-auth-form">
              <div className="splash-auth-feilds mb-30">
                <div className="splash-auth-icon lock-icon">
                  <i className="fa fa-envelope"></i>
                </div>
                <input type="email" placeholder="Email Address" className="splash-input" value={email} onChange={(e) => setEmail(e.target.value)} />

              </div>
              <div className="splash-auth-feilds">
                <div className="splash-auth-icon lock-icon">
                  <i className="fa fa-lock"></i>
                </div>
                <input type={showPassword ? "text" : "password"} placeholder="Password" className="splash-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                <div className="splash-auth-icon eye-icon" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                  <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                </div>
              </div>
            </div>

            <div className="splash-auth-btn">
              {isLoading ? <button type="submit" className="splash-btn splash-login-member w-100">Loading...</button> : <button type="submit" className="splash-btn splash-login-member w-100">Login</button>}
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default Auth;