import React from 'react'
import Layouts from './Layouts'
import TemplateCetak from '../components/admin/TemplateCetak'

const Template = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <TemplateCetak url={url} content={'Template Cetak Rapor'} />
      </Layouts>
    </div>
  )
}

export default Template
