import React from 'react'
import Layouts from './Layouts'
import ProsesUploadNilai from '../components/guru/ProsesUploadNilai'

const UploadNilai = ({ url }) => {
  return (
    <div>
      <Layouts url={url}>
        <ProsesUploadNilai url={url} content={'Proses Upload Nilai'} />
      </Layouts>
    </div>
  )
}

export default UploadNilai
