import React from 'react'

const Footer = () => {
  return (
    <div className="splash-footer-btm">
      <p>Copyright {new Date().getFullYear()} © E-Rapor SMK Turen || by <a href="https://mmc.biz.id" target="_blank" rel="noopener noreferrer">Micromedia Dev</a></p>
    </div>
  )
}

export default Footer
