import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';

const Jurusan = ({ content, url }) => {

  const [jurusan, setJurusan] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedJurusan, setSelectedJurusan] = useState(null);


  const fetchJurusan = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jurusan", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJurusan(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJurusan();
  }, []);

  const handleEdit = (id) => {
    const jurusanToEdit = jurusan.find(j => j.id === id);
    setSelectedJurusan(jurusanToEdit);
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedJurusan(null);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedJurusan(prevState => ({ ...prevState, [name]: value }));

  }


  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(url + "jurusan/" + selectedJurusan.id, {
        kode_jurusan: selectedJurusan.kode_jurusan,
        jurusan: selectedJurusan.jurusan,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Jurusan berhasil diubah");
      fetchJurusan();
      handleCloseModal();
    } catch (error) {
      console.log(error);
      toast.error("Gagal mengubah jurusan");
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kode Jurusan</th>
                        <th>Nama Jurusan</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="4" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        jurusan.map((jurusan, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{jurusan.kode_jurusan}</td>
                            <td>{jurusan.jurusan}</td>
                            <td>
                              <Button variant="primary" className="sm-btn" onClick={() => handleEdit(jurusan.id)}>Edit</Button>
                            </td>
                          </tr>
                        )))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Jurusan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedJurusan && (
            <Form>
              <Form.Group controlId="formKodeJurusan">
                <Form.Label>Kode Jurusan</Form.Label>
                <Form.Control type="text" name="kode_jurusan" defaultValue={selectedJurusan.kode_jurusan} onChange={handleInputChange} />
              </Form.Group>
              <Form.Group controlId="formNamaJurusan">
                <Form.Label>Nama Jurusan</Form.Label>
                <Form.Control type="text" name="jurusan" defaultValue={selectedJurusan.jurusan} onChange={handleInputChange} />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Jurusan
