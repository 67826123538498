import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Guru = ({ content, url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [guru, setGuru] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [tp, setTp] = useState([]);
  const [tpId, setTpId] = useState("");
  const [showAddTugasModal, setShowAddTugasModal] = useState(false);
  const [selectedGuru, setSelectedGuru] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [rombel, setRombel] = useState([]);
  const [mapel, setMapel] = useState([]);
  const [selectedMapel, setSelectedMapel] = useState("");
  const [selectedRombel, setSelectedRombel] = useState("");
  const [showTambahModal, setShowTambahModal] = useState(false);
  const [randomUsername, setRandomUsername] = useState("");
  const [namaGuru, setNamaGuru] = useState('')
  const [isAgama, setIsAgama] = useState('')

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedGuruId, setSelectedGuruId] = useState(null);
  const [selectedGuruData, setSelectedGuruData] = useState({ name: '', is_guru_agama: '', kode_agm: '' });


  const generateRandomUsername = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    setRandomUsername(`${randomNumber}@smkturen.sch.id`);
  };

  useEffect(() => {
    generateRandomUsername();
  }, []);

  const handleOpenTambahModal = () => {
    setShowTambahModal(true);
  };

  const handleCloseTambahModal = () => {
    setShowTambahModal(false);
  };


  const fetchTp = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "tahun-pelajaran-nonaktif", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTp(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchMapel = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "mapel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMapel(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchRombel = async () => {

    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }

  };


  const fetchGuru = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "guru-tugas", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGuru(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchGuru();
    fetchTp();
    fetchRombel();
    fetchMapel();
  }, []);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGuru = guru.filter(guru =>
    guru.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    guru.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredGuru.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredGuru.length / itemsPerPage);

  const handleCopyTugasMengajar = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCopy = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "copy-tugas", { tp_id: tpId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Berhasil salin tugas mengajar");
      setShowModal(false);
      fetchGuru();
    } catch (error) {
      toast.error("Gagal salin tugas mengajar");
    } finally {
      setIsLoading(false);
    }
  }

  const handleDelete = async (email, kode_mp, rombel_nama_) => {
    console.log(email, kode_mp, rombel_nama_);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "delete-tugas",
        { guru_email: email, mp_kode: kode_mp, rombel_nama: rombel_nama_ },
        {
          headers: { Authorization: `Bearer ${token}` },
        });
      toast.success("Berhasil hapus tugas mengajar");
      fetchGuru();
    } catch (error) {
      toast.error("Gagal hapus tugas mengajar");
    }
  }

  const handleAddTugas = (email, name) => {
    setSelectedGuru(name);
    setSelectedEmail(email);
    setShowAddTugasModal(true);
  };

  const handleCloseAddTugasModal = () => {
    setShowAddTugasModal(false);
  };


  const handleEdit = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(`${url}guru/${selectedGuruId}`, selectedGuruData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status === true) {
        toast.success("Berhasil mengedit data guru");
        // Perbarui state guru dengan data yang telah diedit
        setGuru(prevGuru => prevGuru.map(g => g.id === selectedGuruId ? selectedGuruData : g));
        setShowEditModal(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Gagal mengedit data guru");
    }
  }

  const handleSubmitAddTugas = async (e) => {
    e.preventDefault();
    // console.log(selectedRombel, selectedMapel, selectedEmail);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "add-tugas", {
        guru_email: selectedEmail,
        mp_kode: selectedMapel,
        rombel_nama: selectedRombel,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Berhasil tambah tugas mengajar");
      fetchGuru();
      setShowAddTugasModal(false);
      setSelectedRombel("");
      setSelectedMapel("");
    } catch (error) {
      toast.error("Gagal tambah tugas mengajar");
    }
  }

  const handleAddGuru = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {


      const response = await axios.post(url + "register", {
        email: randomUsername,
        name: namaGuru,
        is_guru_agama: isAgama,
        password: 123
      });
      setShowTambahModal(false);
      if (response.data.status === true) {
        toast.success("Berhasil menambahkan guru");
      } else {
        toast.error(response.data.message);
      }
      fetchGuru();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }


  const handleOpenEditModal = (id) => {
    const selectedGuru = guru.find(g => g.id === id);
    setSelectedGuruId(id);
    setSelectedGuruData(selectedGuru); // Simpan data guru yang dipilih
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedGuruId(null);
  };


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="col-md-6 px-3 mt-3">
              <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                <button className="btn btn-primary sm-btn" type="button" title="" onClick={handleOpenTambahModal}>Tambah</button>

                <button className="btn btn-success sm-btn" type="button" title="" onClick={handleCopyTugasMengajar}>Salin Tugas Mengajar</button>

              </div>
            </div>


            <div className="card-body">
              <div className="card table-card">
                <div className="d-flex justify-content-between mb-3">
                  <div></div> {/* Empty div to push search to the right */}
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="form-control w-25"
                  />
                </div>
                <div style={{ overflowX: 'auto' }}>
                  <table id="siswa" className="table table-striped table-bordered dt-responsive nowrap">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>Tugas Mengajar</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="8" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        currentItems.map((guru, index) => (
                          <tr key={guru.id}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>
                              {guru.name}
                              {guru.is_guru_agama === '1' && (
                                <span className="badge badge-danger ml-2">Guru Agama Non Islam</span>
                              )}
                              {guru.agm_desk === '1' && (
                                <span className="badge badge-info ml-2">Kristen</span>
                              )}
                              {guru.agm_desk === '2' && (
                                <span className="badge badge-info ml-2">Katolik</span>
                              )}
                              {guru.agm_desk === '3' && (
                                <span className="badge badge-info ml-2">Hindu</span>
                              )}
                              {guru.agm_desk === '4' && (
                                <span className="badge badge-info ml-2">Budha</span>
                              )}
                              {guru.agm_desk === '5' && (
                                <span className="badge badge-info ml-2">Konghuchu</span>
                              )}
                              {guru.agm_desk === '6' && (
                                <span className="badge badge-info ml-2">Aliran Kepercayaan</span>
                              )}



                            </td>
                            <td>{guru.email}</td>
                            <td>
                              <ol>
                                {guru.rombels.map((rombel, index) => (
                                  <li key={index}>
                                    <ul>
                                      {rombel.mapel.map((mapel, mapelIndex) => (
                                        <li key={mapelIndex}>{rombel.rombel_nama_} - {mapel.kode_mp} <Button className="btn btn-danger sm-btn" onClick={() => handleDelete(guru.email, mapel.kode_mp, rombel.rombel_nama_)}><i className="fas fa-trash"></i></Button></li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ol>
                              <Button className="btn btn-primary sm-btn" onClick={() => handleAddTugas(guru.email, guru.name)} ><i className="fas fa-plus"></i></Button>
                            </td>
                            <td>
                              <Button className="btn btn-primary sm-btn" onClick={() => handleOpenEditModal(guru.id)}>Edit</Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="card-body">
                    <div className="d-flex justify-content-end"> {/* Added this div */}
                      <div className="fb-pagination">
                        <ul>
                          <li>
                            <a href="javascript:;" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                            </a>
                          </li>
                          {Array.from({ length: 5 }, (_, index) => {
                            const pageNumber = currentPage - 2 + index;
                            if (pageNumber > 0 && pageNumber <= totalPages) {
                              return (
                                <li key={pageNumber}>
                                  <a
                                    href="javascript:;"
                                    className={currentPage === pageNumber ? "active" : ""}
                                    onClick={() => setCurrentPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          })}
                          <li>
                            <a href="javascript:;" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                        <div className="record-info">
                          <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredGuru.length)} of {filteredGuru.length} records</span>
                        </div>
                      </div>
                    </div> {/* End of added div */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Salin Tugas Mengajar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your modal content here */}
          <p>Copy Tugas Mengajar dari tahun pelajaran</p>
          <select className="form-control mt-2" onChange={(e) => setTpId(e.target.value)}>
            <option value="">Pilih Tahun Pelajaran</option>
            {tp.map((tp, index) => (
              <option key={index} value={tp.id}>{tp.thn_pel}</option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCopy} disabled={isLoading}>
            {isLoading ? "Loading..." : "Copy"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Add Tugas */}

      <Modal show={showAddTugasModal} onHide={handleCloseAddTugasModal}>
        <form onSubmit={handleSubmitAddTugas}>
          <Modal.Header closeButton>
            <Modal.Title>Tambah Tugas Mengajar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Nama : {selectedGuru}</p>
            <p>Email : {selectedEmail}</p>

            <Alert variant="warning" className='px-3'>
              <Alert.Heading>Info</Alert.Heading>
              <p>
                Kode Mapel PAG_1 khusus untuk Agama Non Islam
              </p>
            </Alert>


            <div className="form-group mt-2">
              <label>Pilih Rombel</label>
              <select className="form-control" onChange={(e) => setSelectedRombel(e.target.value)} required>
                <option value="">Pilih Rombel</option>
                {rombel.map((rombel, index) => (
                  <option key={index} value={rombel.nama_rombel}>{rombel.nama_rombel}</option>
                ))}
              </select>
            </div>
            <div className="form-group mt-2">
              <label>Pilih Mapel</label>
              <select className="form-control" onChange={(e) => setSelectedMapel(e.target.value)} required>
                <option value="">Pilih Mapel</option>
                {mapel.map((mapel, index) => (
                  <option key={index} value={mapel.kode_mp}>{mapel.kode_mp} - {mapel.nama_mp}</option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddTugasModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Simpan
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Modal Tambah Guru */}
      <Modal show={showTambahModal} onHide={handleCloseTambahModal}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Data Guru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddGuru}>
            <div className="mt-3">
              <p>Username</p>
              <input type='text' className='form-control' value={randomUsername} readOnly required />
            </div>
            <div className="mt-3">
              <p>Nama Guru</p>
              <input type='text' className='form-control' value={namaGuru} onChange={(e) => setNamaGuru(e.target.value)} required />
            </div>
            <div className="mt-3">
              <p>Apakah Guru Agama?</p>
              <select className='form-control' value={isAgama} onChange={(e) => setIsAgama(e.target.value)} required>
                <option value="">Pilih Kategori</option>
                <option value="1">Ya</option>
                <option value="0">Tidak</option>
              </select>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseTambahModal}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      {/* Modal Edit */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data Guru</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="mt-3">
            <p>Nama Guru</p>
            <input
              type='text' className='form-control'
              value={selectedGuruData.name}
              onChange={(e) => setSelectedGuruData({ ...selectedGuruData, name: e.target.value })}
            />
          </div>
          <div className="mt-3">
            <p>Apakah Guru Agama?</p>
            <select className='form-control' value={selectedGuruData.is_guru_agama} onChange={(e) => setSelectedGuruData({ ...selectedGuruData, is_guru_agama: e.target.value })} required>
              <option value="">Pilih Kategori</option>
              <option value="1">Ya</option>
              <option value="0">Tidak</option>
            </select>
          </div>
          {selectedGuruData.is_guru_agama === '1' && (
            <div className="mt-3">
              <p>Pilih Agama</p>
              <select className='form-control' value={selectedGuruData.kode_agm} onChange={(e) => setSelectedGuruData({ ...selectedGuruData, kode_agm: e.target.value })} required>
                <option value="">Pilih Agama</option>
                <option value="1">Kristen</option>
                <option value="2">Katolik</option>
                <option value="3">Hindu</option>
                <option value="4">Budha</option>
                <option value="5">Konghuchu</option>
                <option value="6">Aliran Kepercayaan</option>
              </select>
            </div>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default Guru
