import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ProgressBar, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';



const LeggerDkn = ({ url, content }) => {
  const { nama_rombel } = useParams();

  const [listMapel, setListMapel] = useState([]);
  const [siswa, setSiswa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nilai, setNilai] = useState({});
  const [totalNilai, setTotalNilai] = useState({});
  const [rankedSiswa, setRankedSiswa] = useState([]);

  const fetchListMapel = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.post(url + "get-list-mapel", {
        rombel: nama_rombel,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setListMapel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSiswa = async () => {
    const token = localStorage.getItem('token');
    try {
      setIsLoading(true);
      const response = await axios.post(url + 'siswa-by-rombel', {
        rombel_nama: nama_rombel
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSiswa(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchAllNilai = async () => {
    if (siswa.length === 0 || listMapel.length === 0) {
      console.log('Siswa or ListMapel is empty');
      return;
    }

    const token = localStorage.getItem('token');
    const maxConcurrentRequests = 5; // Adjust this number based on your server's capacity
    const requests = [];
    const nilaiData = {};

    const fetchNilai = async (s, mapel) => {
      try {
        const response = await axios.post(url + 'get-nilai-by-name', {
          rombel: nama_rombel,
          kdmp: mapel.mp,
          nis: s.nis
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return { nis: s.nis, mapel: mapel.mp, nilai: response.data.data };
      } catch (error) {
        console.log(error);
        return { nis: s.nis, mapel: mapel.mp, nilai: null };
      }
    };

    const processRequests = async () => {
      while (requests.length > 0) {
        const batch = requests.splice(0, maxConcurrentRequests);
        const results = await Promise.all(batch);
        results.forEach(({ nis, mapel, nilai }) => {
          if (!nilaiData[nis]) {
            nilaiData[nis] = {};
          }
          nilaiData[nis][mapel] = nilai ? nilai : {};
        });
      }
    };

    for (const s of siswa) {
      for (const mapel of listMapel) {
        requests.push(fetchNilai(s, mapel));
      }
    }

    try {
      setIsLoading(true);
      await processRequests();
      console.log('Fetched Nilai Data:', nilaiData); // Debugging line
      setNilai(nilaiData);
      calculateTotalNilai(nilaiData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Simpan Total Nilai dan Peringkat
  const saveTotalNilaiDanPeringkat = async (totalNilaiData, rankedSiswa) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(url + 'save-jml-rank', {
        totalNilai: totalNilaiData,
        peringkat: rankedSiswa.map((s, index) => ({
          nis: s.nis,
          peringkat: index + 1
        })),
        rombel: nama_rombel
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.log('Error saving data:', error);
    }
  };

  const calculateTotalNilai = (nilaiData) => {
    const totalNilaiData = {};
    siswa.forEach(s => {
      let total = 0;
      listMapel.forEach(mapel => {
        const nilaiMapel = nilaiData[s.nis] && nilaiData[s.nis][mapel.mp] ? parseFloat(nilaiData[s.nis][mapel.mp].olah_na) : 0;
        total += isNaN(nilaiMapel) ? 0 : nilaiMapel; // Ensure nilaiMapel is a valid number
      });
      totalNilaiData[s.nis] = total;
    });
    setTotalNilai(totalNilaiData);
    rankSiswa(totalNilaiData);
  };



  // const calculateTotalNilai = (nilaiData) => {
  //   const totalNilaiData = {};
  //   siswa.forEach(s => {
  //     let total = 0;
  //     listMapel.forEach(mapel => {
  //       const nilaiMapel = nilaiData[s.nis] && nilaiData[s.nis][mapel.mp] ? nilaiData[s.nis][mapel.mp].olah_na : 0;
  //       total += parseFloat(nilaiMapel);
  //     });
  //     totalNilaiData[s.nis] = total;
  //   });
  //   setTotalNilai(totalNilaiData);
  //   rankSiswa(totalNilaiData);
  // };

  // const rankSiswa = (totalNilaiData) => {
  //   const ranked = [...siswa].sort((a, b) => totalNilaiData[b.nis] - totalNilaiData[a.nis]);
  //   setRankedSiswa(ranked);
  // };

  const rankSiswa = (totalNilaiData) => {
    const getAdditionalScore = (s, mapelNames) => {
      for (const mapelName of mapelNames) {
        if (nilai[s.nis] && nilai[s.nis][mapelName]) {
          return parseFloat(nilai[s.nis][mapelName].olah_na);
        }
      }
      return 0;
    };

    const ranked = [...siswa].sort((a, b) => {
      const totalA = totalNilaiData[a.nis];
      const totalB = totalNilaiData[b.nis];

      if (totalA !== totalB) {
        return totalB - totalA;
      }

      // If total scores are the same, compare PAG or PAG_1 and PP
      const pagA = getAdditionalScore(a, ['PAG', 'PAG_1']);
      const pagB = getAdditionalScore(b, ['PAG', 'PAG_1']);
      if (pagA !== pagB) {
        return pagB - pagA;
      }

      const ppA = getAdditionalScore(a, ['PP']);
      const ppB = getAdditionalScore(b, ['PP']);
      return ppB - ppA;
    });

    setRankedSiswa(ranked);
    saveTotalNilaiDanPeringkat(totalNilaiData, ranked);
  };


  const exportToExcel = () => {
    const data = rankedSiswa.map((s, index) => {
      const row = {
        '#': index + 1,
        'NIS': s.nis,
        'Nama Siswa': s.nama,
      };
      listMapel.forEach((mapel) => {
        row[mapel.mp] = nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-';
      });
      row['Total Nilai'] = totalNilai[s.nis];
      row['Peringkat'] = isNaN(totalNilai[s.nis]) ? '-' : index + 1;
      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, nama_rombel); // Ensure nama_rombel is a string
    XLSX.writeFile(workbook, `LEGGER ${nama_rombel}.xlsx`); // Ensure nama_rombel is a string
  };



  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchAllNilai();
      setIsLoading(false)
    };
    fetchData();
  }, [siswa, listMapel]);

  useEffect(() => {
    fetchSiswa();
    fetchListMapel();
  }, []);



  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {nama_rombel}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">

                <div className="col-12 mb-3">
                  {!isLoading && (
                    <button onClick={exportToExcel} className="btn btn-primary sm-btn">Export to Excel</button>
                  )}
                </div>

                <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '400px' }}>

                  <table className="table table-styled mb-0">

                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>

                      <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2}>NIS</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={listMapel.length} className="text-center">MATA PELAJARAN</th>
                        <th rowSpan={2}>Total Nilai</th>
                        <th rowSpan={2}>Peringkat</th>
                      </tr>
                      <tr>
                        {listMapel.map((mapel, index) => (
                          <th key={index} className='text-center'>{mapel.mp}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        rankedSiswa.map((s, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{s.nis}</td>
                            <td>{s.nama}</td>
                            {listMapel.map((mapel, mapelIndex) => (
                              <td key={mapelIndex} className='text-center'>
                                {nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-'}
                              </td>
                            ))}
                            <td>{totalNilai[s.nis]}</td>
                            <td>{isNaN(totalNilai[s.nis]) ? '-' : index + 1}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeggerDkn;