import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';



const ProsesUploadNilai = ({ url, content }) => {
  const { kode_mp, nama_rombel, kurikulum } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nilai, setNilai] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [statusLock, setStatusLock] = useState([])
  const [mee, setMee] = useState([])

  const navigate = useNavigate()

  const fetchStatusLock = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "status-lock", {
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setStatusLock(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  if (statusLock && statusLock === '1') {
    toast.error('Nilai Sudah Dikunci Wali Kelas!')
    navigate(`/guru/input-nilai/${kode_mp}/${nama_rombel}/${kurikulum}`);
  }


  const fetchMe = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(url + "me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setMee(response)

    setLoading(false)
  }




  const fetchNilai = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.post(url + "get-nilai-upload", {
        rombel: nama_rombel,
        kodemp: kode_mp,
        is_agm: localStorage.getItem('kdagm')
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNilai(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchNilai();
    fetchStatusLock();
  }, []);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Download Template
  const handleDownloadTemplate = async () => {
    console.log("Download Template button clicked"); // Tambahkan log ini
    const token = localStorage.getItem("token");

    console.log("URL:", url); // Tambahkan log ini

    try {
      const response = await axios({
        url: url + "template-nilai",
        method: "POST",
        data: {
          kode_mp: kode_mp,
          rombel: nama_rombel,
          kurikulum: kurikulum,
          is_agama: localStorage.getItem('kdagm')
        },
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "Template Nilai - " + nama_rombel + " - " + kode_mp + ".xlsx";
      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("Invalid file extension");
      }

      const url_ = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url_;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setLoading(false);

    }
  }

  // Upload Template

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Proses Upload Template
  const uploadTemplate = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const validExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validExtensions.includes(fileExtension)) {
      alert("Invalid file type. Please upload an Excel file.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds the limit of 5MB.");
      return;
    }

    setLoadingUpload(true);


    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("kode_mp", kode_mp);
    formData.append("rombel", nama_rombel);
    formData.append("kurikulum", kurikulum);
    formData.append("is_agm", mee?.data?.user?.agm_desk);


    try {
      setIsModalOpen(false);
      await axios.post(url + "import-nilai", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await fetchNilai();

      toast.success("Template Nilai Berhasil Diupload!");

    } catch (error) {
      // console.error("Error uploading file:", error);
      // alert("Error uploading file.");
      toast.error("Error " + error.response.data.error);

    } finally {
      setLoadingUpload(false);

    }
  };


  useEffect(() => {
    fetchMe()
  }, []);





  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {nama_rombel} - {kode_mp}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard-guru"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="card-header">



              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="btn-group btn-group-pill" role="group" aria-label="Basic example">

                  <NavLink to={`/guru/input-nilai/${kode_mp}/${nama_rombel}/${kurikulum}`} className="btn btn-warning sm-btn" type="button" title="">Kembali</NavLink>
                  <button className="btn btn-primary sm-btn" type="button" title="" onClick={handleOpenModal}>Upload Nilai</button>


                </div>
                <span>{kurikulum === '1' ? 'Type : K-13' : kurikulum === '2' ? 'Type : Kurikulum Merdeka' : kurikulum}</span>
              </div>


            </div>

            <div className="card-body">
              <div className="row">
                <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '400px' }}>

                  <table className="table table-styled mb-0">

                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                      <tr>
                        <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>#</th>
                        <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>NIS</th>
                        <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>Nama Siswa</th>
                        {kurikulum === '2' ? (
                          <React.Fragment>
                            <th colSpan={6} style={{ padding: '5px', textAlign: 'center' }}>Nilai Intrakurikuler</th>
                            <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>Nilai STS</th>
                            <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>Nilai SAS</th>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <th colSpan={6} style={{ padding: '5px', textAlign: 'center' }}>Nilai Pengetahuan</th>
                            <th colSpan={6} style={{ padding: '5px', textAlign: 'center' }}>Nilai Keterampilan</th>
                            <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>Nilai PTS</th>
                            <th rowSpan={2} style={{ padding: '5px', textAlign: 'center' }}>Nilai PAS</th>
                          </React.Fragment>
                        )}
                      </tr>
                      <tr>
                        {kurikulum === '2' ? (
                          <React.Fragment>
                            <th style={{ padding: '5px', textAlign: 'center' }}>CP-1</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>CP-2</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>CP-3</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>CP-4</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>CP-5</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>CP-6</th>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NP-1</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NP-2</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NP-3</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NP-4</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NP-5</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NP-6</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NK-1</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NK-2</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NK-3</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NK-4</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NK-5</th>
                            <th style={{ padding: '5px', textAlign: 'center' }}>NK-6</th>
                          </React.Fragment>
                        )}
                      </tr>
                    </thead>

                    <tbody>

                      {isLoading ? (
                        <tr>
                          <td colSpan={kurikulum === '2' ? 12 : 17} className="text-center">Loading...</td>
                        </tr>
                      ) : (

                        nilai.map((item, index) => (
                          <tr key={index}>

                            <td style={{ textAlign: 'center' }}>{index + 1}</td>

                            <td style={{ textAlign: 'center' }}>{item.nis}</td>

                            <td >{item.nama}</td>
                            {kurikulum === '2' ? (

                              <>

                                <td style={{ textAlign: 'center' }}>{item.p1}</td>

                                <td style={{ textAlign: 'center' }}>{item.p2}</td>
                                <td style={{ textAlign: 'center' }}>{item.p3}</td>
                                <td style={{ textAlign: 'center' }}>{item.p4}</td>

                                <td style={{ textAlign: 'center' }}>{item.p5}</td>

                                <td style={{ textAlign: 'center' }}>{item.p6}</td>
                              </>
                            ) : (
                              <>
                                <td style={{ textAlign: 'center' }}>{item.p1}</td>

                                <td style={{ textAlign: 'center' }}>{item.p2}</td>

                                <td style={{ textAlign: 'center' }}>{item.p3}</td>
                                <td style={{ textAlign: 'center' }}>{item.p4}</td>

                                <td style={{ textAlign: 'center' }}>{item.p5}</td>
                                <td style={{ textAlign: 'center' }}>{item.p6}</td>

                                <td style={{ textAlign: 'center' }}>{item.k1}</td>
                                <td style={{ textAlign: 'center' }}>{item.k2}</td>
                                <td style={{ textAlign: 'center' }}>{item.k3}</td>

                                <td style={{ textAlign: 'center' }}>{item.k4}</td>

                                <td style={{ textAlign: 'center' }}>{item.k5}</td>
                                <td style={{ textAlign: 'center' }}>{item.k6}</td>

                              </>

                            )}
                            <td style={{ textAlign: 'center' }}>{item.n_sts}</td>
                            <td style={{ textAlign: 'center' }}>{item.n_sas}</td>
                          </tr>

                        ))
                      )}
                    </tbody>

                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Nilai</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <button className="btn btn-primary sm-btn" onClick={handleDownloadTemplate}>Download Template</button>

          <input
            type="file"
            onChange={handleFileChange}
            className="form-control mt-3"
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadTemplate}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ProsesUploadNilai
