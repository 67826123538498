import React, { useState, useEffect } from 'react'
import { useInsertionEffect } from 'react';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
import { ProgressBar, Spinner } from 'react-bootstrap';



const StatusNilai = ({ url, content }) => {

  const { nama_rombel } = useParams()
  const [listMapel, setListMapel] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const [dataGuru, setDataGuru] = useState({})
  const [dataStatus, setDataStatus] = useState({})
  const [dataProgress, setDataProgress] = useState({})
  const [statusLock, setStatusLock] = useState([])



  const fetchListMapel = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true)
      const response = await axios.post(url + "get-list-mapel", {
        rombel: nama_rombel,
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setListMapel(response.data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGuru = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-guru-mengajar", {
        mp: mp,
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataGuru(prevState => ({
        ...prevState,
        [mp]: response.data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  const fetchStatus = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-status-nilai", {
        mp: mp,
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataStatus(prevState => ({
        ...prevState,
        [mp]: response.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  const fetchProgress = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-progress-nilai", {
        mp: mp,
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataProgress(prevState => ({
        ...prevState,
        [mp]: response.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  const handleKunciNilai = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "kunci-nilai", {
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      toast.success('Data Nilai Berhasil Dikunci!')
      await fetchStatusLock()
    } catch (error) {
      console.log(error);
    }
  };
  const handleBukaKunci = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "buka-kunci-nilai", {
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      toast.success('Kunci Nilai Berhasil Dibuka!')
      await fetchStatusLock()
    } catch (error) {
      console.log(error);
    }
  };


  const fetchStatusLock = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "status-lock", {
        rombel: nama_rombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setStatusLock(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };




  useEffect(() => {
    fetchListMapel();
    fetchStatusLock();
  }, []);

  useEffect(() => {
    listMapel.forEach(item => {
      fetchGuru(item.mp);
      fetchStatus(item.mp);
      fetchProgress(item.mp);
    });
  }, [listMapel]);


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {nama_rombel}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">

                <div className="col-md-6 mb-3">
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    <button
                      className={`btn ${statusLock === '1' ? 'btn-danger' : 'btn-info'} sm-btn`}
                      type="button"
                      onClick={statusLock === '1' ? handleBukaKunci : handleKunciNilai}
                    >
                      {statusLock === '1' ? 'Buka Kunci' : 'Kunci Nilai'}
                    </button>


                  </div>
                </div>
                <div className="col-md-3"></div>


                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Mapel</th>
                        <th>Guru</th>
                        <th>Status</th>
                        <th>Progres Pengisian Nilai</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? <tr><td colSpan="5" className="text-center"><Spinner animation="border" size="sm" /></td></tr> :
                        listMapel.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.nama_mp}</td>
                            <td>{dataGuru[item.mp]?.name || 'Loading...'}</td>
                            <td>
                              {dataStatus[item.mp] === undefined ? (
                                'Loading...'
                              ) : dataStatus[item.mp]?.data === '0' ? (
                                <span className="badge badge-danger">Belum Kirim</span>
                              ) : (
                                <span className="badge badge-success">Sudah Kirim</span>
                              )}
                            </td>
                            <td>
                              {dataProgress[item.mp]?.data !== undefined && dataProgress[item.mp]?.data !== null ? (
                                <ProgressBar
                                  now={dataProgress[item.mp]?.data}
                                  label={`${dataProgress[item.mp]?.data}%`}
                                  variant={
                                    dataProgress[item.mp]?.data === 0 ? 'danger' :
                                      dataProgress[item.mp]?.data < 100 ? 'warning' :
                                        'success'
                                  }
                                  style={{ height: '20px' }} // Adjust the height as needed
                                />
                              ) : 'Loading...'}
                            </td>


                          </tr>
                        ))
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

export default StatusNilai
