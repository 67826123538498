import React, { useState, useEffect } from 'react'
import Dashboard from '../components/guru/Dashboard';
import Layouts from './Layouts'
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";

const DashboardGuru = ({ url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);


  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);




  useEffect(() => {
    fetchUser();
  }, []);


  return (
    <div>
      <Layouts user={user && user} url={url}>
        <Dashboard url={url} />
      </Layouts>
    </div>
  )
}

export default DashboardGuru
