import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';



const Kakomli = ({ content, url }) => {

  const [level, setLevel] = useState('')
  const [jurusan, setJurusan] = useState([])
  const [kakomli, setKakomli] = useState([]);


  const fetchJurusan = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "jurusan-by-kls", {
        level: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log(response.data.data)
      setJurusan(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchKakomli = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "guru", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKakomli(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }



  const handleKakomliChange = async (e, kdjur) => {
    console.log('email', e.target.value);
    console.log('jurus', kdjur);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "set-kakomli", {
        email: e.target.value,
        kd_jurusan: kdjur,
        level: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Berhasil menyimpan Kakomli");
      fetchJurusan();
    } catch (error) {
      console.log(error);
    }
  }



  useEffect(() => {
    fetchJurusan();
    fetchKakomli();
  }, [level]);


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-header">
              <div className="row">
                <div className="col-md-6">
                  <select className="form-control" onChange={(e) => setLevel(e.target.value)}>
                    <option value="">Pilih Level / Kelas</option>
                    <option value="1">10</option>
                    <option value="2">11</option>
                    <option value="3">12</option>
                  </select>
                </div>

              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kode Jurusan</th>
                        <th>Nama Jurusan</th>
                        <th>Nama Kakomli</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jurusan.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.kode_jurusan}</td>
                          <td>{item.jurusan}</td>
                          <td>

                            <select className='form-control' onChange={(e) => handleKakomliChange(e, item.kode_jurusan)}>
                              <option value="">Pilih Kakomli</option>
                              {kakomli.map((k) => (
                                <option
                                  key={k.id}
                                  value={k.email}
                                  selected={item.kakomli && item.kakomli.email === k.email}
                                >
                                  {k.name}
                                </option>
                              ))}
                            </select>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Kakomli
