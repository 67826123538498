import React, { useState, useEffect } from 'react'
import { useInsertionEffect } from 'react';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
import { ProgressBar, Spinner } from 'react-bootstrap';



const StatusNilai = ({ url, content }) => {

  const [jurusan, setJurusan] = useState([]);
  const [level, setLevel] = useState('');
  const [selectedJurusan, setSelectedJurusan] = useState('');
  const [selectedRombel, setSelectedRombel] = useState('');
  const [rombel, setRombel] = useState([])


  const [listMapel, setListMapel] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const [dataGuru, setDataGuru] = useState({})
  const [dataStatus, setDataStatus] = useState({})
  const [dataProgress, setDataProgress] = useState({})
  const [statusLock, setStatusLock] = useState([])

  const fetchJurusan = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jurusan", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJurusan(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRombelbyId = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(url + "rombel-by-jurusan", {
        jurusan_id: selectedJurusan,
        level_id: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const fetchListMapel = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true)
      const response = await axios.post(url + "get-list-mapel", {
        rombel: selectedRombel,
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setListMapel(response.data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGuru = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-guru-mengajar", {
        mp: mp,
        rombel: selectedRombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataGuru(prevState => ({
        ...prevState,
        [mp]: response.data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  const fetchStatus = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-status-nilai", {
        mp: mp,
        rombel: selectedRombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataStatus(prevState => ({
        ...prevState,
        [mp]: response.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  const fetchProgress = async (mp) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-progress-nilai", {
        mp: mp,
        rombel: selectedRombel
      },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      setDataProgress(prevState => ({
        ...prevState,
        [mp]: response.data
      }));
    } catch (error) {
      console.log(error);
    }
  };


  // const handleKunciNilai = async (mp) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.post(url + "kunci-nilai", {
  //       rombel: nama_rombel
  //     },
  //       {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });
  //     toast.success('Data Nilai Berhasil Dikunci!')
  //     await fetchStatusLock()
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleBukaKunci = async (mp) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.post(url + "buka-kunci-nilai", {
  //       rombel: nama_rombel
  //     },
  //       {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });
  //     toast.success('Kunci Nilai Berhasil Dibuka!')
  //     await fetchStatusLock()
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  // const fetchStatusLock = async (mp) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.post(url + "status-lock", {
  //       rombel: nama_rombel
  //     },
  //       {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });
  //     setStatusLock(response.data.data)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };



  useEffect(() => {
    fetchRombelbyId(selectedJurusan, level);
  }, [selectedJurusan, level]);


  useEffect(() => {
    fetchJurusan()
    // fetchStatusLock();
  }, []);


  useEffect(() => {
    fetchListMapel();
  }, [selectedRombel]);



  useEffect(() => {
    listMapel.forEach(item => {
      fetchGuru(item.mp);
      fetchStatus(item.mp);
      fetchProgress(item.mp);
    });
  }, [listMapel]);


  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} </h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-4">
                  <select className="form-control" onChange={(e) => {
                    setLevel(e.target.value);
                    setSelectedJurusan('');
                    setSelectedRombel('');
                  }}>
                    <option value="">Pilih Level / Kelas</option>
                    <option value="1">10</option>
                    <option value="2">11</option>
                    <option value="3">12</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <select className='form-control' value={selectedJurusan} onChange={(e) => {
                    setSelectedJurusan(e.target.value);
                    setSelectedRombel('');
                  }} disabled={!level}>
                    <option value="">Pilih Jurusan</option>
                    {jurusan.map((j) => (
                      <option key={j.id} value={j.id}>{j.jurusan}</option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4">
                  <select className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                    <option value="">Pilih Rombel</option>
                    {rombel.map((r) => (
                      <option key={r.nama_rombel} value={r.nama_rombel}>{r.nama_rombel}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {selectedRombel && (
                    <div>
                      <h4> Progress Pengisian Nilai Kelas {selectedRombel}</h4>
                    </div>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    {/* <button
                    className={`btn ${statusLock === '1' ? 'btn-danger' : 'btn-info'} sm-btn`}
                    type="button"
                    onClick={statusLock === '1' ? handleBukaKunci : handleKunciNilai}
                  >
                    {statusLock === '1' ? 'Buka Kunci' : 'Kunci Nilai'}
                  </button> */}


                  </div>
                </div>



                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Mapel</th>
                        <th>Guru</th>
                        <th>Status</th>
                        <th>Progres Pengisian Nilai</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedRombel ? (
                        isLoading ? <tr><td colSpan="5" className="text-center"><Spinner animation="border" size="sm" /></td></tr> :
                          listMapel.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.nama_mp}</td>
                              <td>{dataGuru[item.mp]?.name || 'Loading...'}</td>
                              <td>
                                {dataStatus[item.mp] === undefined ? (
                                  'Loading...'
                                ) : dataStatus[item.mp]?.data === '0' ? (
                                  <span className="badge badge-danger">Belum Kirim</span>
                                ) : (
                                  <span className="badge badge-success">Sudah Kirim</span>
                                )}
                              </td>
                              <td>
                                {dataProgress[item.mp]?.data !== undefined && dataProgress[item.mp]?.data !== null ? (
                                  <ProgressBar
                                    now={dataProgress[item.mp]?.data}
                                    label={`${dataProgress[item.mp]?.data}%`}
                                    variant={
                                      dataProgress[item.mp]?.data === 0 ? 'danger' :
                                        dataProgress[item.mp]?.data < 100 ? 'warning' :
                                          'success'
                                    }
                                    style={{ height: '20px' }} // Adjust the height as needed
                                  />
                                ) : 'Loading...'}
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr><td colSpan="5" className="text-center">Silahkan Pilih Level, Jurusan dan Rombel Terlebih Dahulu</td></tr>
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

export default StatusNilai
