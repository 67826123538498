import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const Rombel = ({ content, url }) => {
  const [level, setLevel] = useState('');
  const [jurusan, setJurusan] = useState([]);
  const [selectedJurusan, setSelectedJurusan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [wakel, setWakel] = useState([]);
  const [rombel, setRombel] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [tpNonAktif, setTpNonAktif] = useState([]);
  const [tpId, setTpId] = useState('');
  const [copyWakel, setCopyWakel] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [nama, setNama] = useState('')

  const handleAdd = () => {
    setShowAddModal(true);
  };


  const fetchWakel = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "guru", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWakel(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTpNonAktif = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "tahun-pelajaran-nonaktif", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTpNonAktif(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }


  const fetchJurusan = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jurusan", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJurusan(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRombelbyId = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(url + "rombel-by-jurusan", {
        jurusan_id: selectedJurusan,
        level_id: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const fetchRombel = async () => {

    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }

  };

  useEffect(() => {
    fetchJurusan();
    fetchWakel();
    fetchTpNonAktif();
  }, []);

  useEffect(() => {
    setSelectedJurusan('');
    setRombel([]);
  }, [level]);

  useEffect(() => {
    fetchRombelbyId(selectedJurusan, level);
  }, [selectedJurusan, level]);

  useEffect(() => {
    if (level === '' || selectedJurusan === '') {
      fetchRombel();
    }
  }, [level, selectedJurusan]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rombel.slice(indexOfFirstItem, indexOfLastItem);


  const handleDetail = (r) => {
    console.log(r);
  }

  const handleCopyRombel = () => {
    console.log('Copy Rombel');
    setShowModal(true);
  }

  const handleCopy = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "copy-rombel", {
        tp_id: tpId,
        wakel: copyWakel
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      setShowModal(false);
      toast.success("Berhasil menyalin rombel");
      fetchRombel();

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleWakelChange = async (e, wakel, id, nama_rombel) => {
    console.log(e.target.value);
    console.log(nama_rombel);

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "set-wali-kelas", {
        wali_kelas: e.target.value,
        nama_rombel: nama_rombel
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Berhasil menyimpan wali kelas");
      fetchRombel();
    } catch (error) {
      console.log(error);
    }
  }

  const handleDelete = async (namaRombel) => {
    const result = await Swal.fire({
      title: `Apakah Anda yakin menghapus rombel ${namaRombel}?`,
      text: `Data yang telah terhapus tidak dapat dikembalikan!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Batal'
    });

    if (!result.isConfirmed) {
      return;
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "delete-rombel", {
        rombel: namaRombel
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Berhasil menghapus rombel!");
      fetchRombel();
    } catch (error) {
      console.log(error);
    }
  };


  const handleAddRombel = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "add-rombel", {
        nama_rombel: nama,
        jurusan_id: selectedJurusan,
        level_id: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setShowAddModal(false);
      toast.success("Berhasil menambahkan rombel");
      fetchRombel();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6">
                  <select className="form-control" onChange={(e) => setLevel(e.target.value)}>
                    <option value="">Pilih Level / Kelas</option>
                    <option value="1">10</option>
                    <option value="2">11</option>
                    <option value="3">12</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <select
                    className='form-control'
                    value={selectedJurusan}
                    onChange={(e) => setSelectedJurusan(e.target.value)}
                    disabled={!level} // Disable if level is empty
                  >
                    <option value="">Pilih Jurusan</option>
                    {jurusan.map((j) => (
                      <option key={j.id} value={j.id}>{j.jurusan}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body">

              <div className="col-md-6 mb-3">
                <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                  <button className="btn btn-primary sm-btn" type="button" title="" onClick={handleAdd}>Tambah</button>

                  <button className="btn btn-success sm-btn" type="button" title="" onClick={handleCopyRombel}>Salin Rombel</button>
                </div>
              </div>


              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nama Rombel</th>
                        <th>Tingkat</th>
                        <th>Jurusan</th>
                        <th>Jumlah Siswa</th>
                        <th>Wali Kelas</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="7" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        currentItems.map((r, index) => (
                          <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{r.nama_rombel}</td>
                            <td>{r.level}</td>
                            <td>{r.jurusan}</td>
                            <td>{r.jumlah_siswa}</td>
                            <td>
                              <select className='form-control' onChange={(e) => handleWakelChange(e, r.wakel ? r.wakel.email : '', r.id, r.nama_rombel)}>
                                <option value="">Pilih Wali Kelas</option>
                                {wakel.map((w) => (
                                  <option
                                    key={w.id}
                                    value={w.email}
                                    selected={r.wakel && r.wakel.email === w.email}
                                  >
                                    {w.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>

                              <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                                <Link to={`/detail-rombel/${r.nama_rombel}`} className='btn btn-primary sm-btn'>Detail</Link>
                                <button className="btn btn-danger sm-btn" onClick={() => handleDelete(r.nama_rombel)} type="button" title="">Delete</button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}

                    </tbody>
                  </table>
                  <div className="pagination d-flex justify-content-end mt-3">
                    {Array.from({ length: Math.ceil(rombel.length / itemsPerPage) }, (_, i) => (
                      <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={`btn ${currentPage === i + 1 ? 'squer-btn btn-outline primary-outline sm-btn' : 'squer-btn btn-outline secondary-outline sm-btn'}`}
                      >
                        {i + 1}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Salin Rombel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCopy}>
            <p>Copy Rombel dari Tahun Pelajaran</p>
            <select className='form-control mt-2' onChange={(e) => setTpId(e.target.value)} required>
              <option value="">Pilih Tahun Pelajaran</option>
              {tpNonAktif.map((tp) => (
                <option key={tp.id} value={tp.id}>{tp.thn_pel}</option>
              ))}
            </select>

            <p className='mt-3'>Copy Wali Kelas?</p>
            <select className='form-control mt-2' onChange={(e) => setCopyWakel(e.target.value)} required>
              <option value="">Pilih</option>
              <option value="1">Ya</option>
              <option value="0">Tidak</option>
            </select>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Copy'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>


      {/* Modal Add */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Rombel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3">
            <p>Level / Tingkat</p>
            <select className="form-control" value={level} onChange={(e) => setLevel(e.target.value)}>
              <option value="">Pilih Level / Kelas</option>
              <option value="1">10</option>
              <option value="2">11</option>
              <option value="3">12</option>
            </select>
          </div>
          <div className="mt-3">
            <p>Jurusan</p>
            <select className='form-control' value={selectedJurusan} onChange={(e) => setSelectedJurusan(e.target.value)}>
              <option value="">Pilih Jurusan</option>
              {jurusan.map((j) => (
                <option key={j.id} value={j.id}>{j.jurusan}</option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <p>Nama Rombel</p>
            <input type='text' className='form-control' value={nama} onChange={(e) => setNama(e.target.value)} required />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddRombel}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Rombel
