import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Kenaikan = ({ url, content }) => {
  const [rombel, setRombel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [thnlalu, setThnLalu] = useState([]);
  const [thnini, setThnIni] = useState([]);
  const [level, setLevel] = useState([]);
  const [selectedLevelAwal, setSelectedLevelAwal] = useState('');
  const [kelasAwal, setKelasAwal] = useState([]);
  const [selectedRombelAwal, setSelectedRombelAwal] = useState('');
  const [siswaThnLalu, setSiswaThnLalu] = useState([]);
  const [selectedSiswaAwal, setSelectedSiswaAwal] = useState([]);

  const [selectedLevelTujuan, setSelectedLevelTujuan] = useState('');
  const [kelasTujuan, setKelasTujuan] = useState([]);
  const [selectedRombelTujuan, setSelectedRombelTujuan] = useState('');
  const [siswaThnIni, setSiswaThnIni] = useState([]);

  const fetchRombel = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(url + "rombel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchThnLalu = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(url + "tahun-lalu", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setThnLalu(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchThnIni = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(url + "tahun-pelajaran-aktif", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setThnIni(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchLevel = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(url + "level", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLevel(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchKelasAwal = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "rombel-by-thn", {
        tp_id: thnlalu.id,
        level_id: selectedLevelAwal
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKelasAwal(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };


  const isiKelasAwal = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "isi-rombel-by-thn", {
        rombel_nama: selectedRombelAwal,
        tp_id: thnlalu.id,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSiswaThnLalu(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const fetchKelasSaatIni = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "rombel-by-thn", {
        tp_id: thnini.id,
        level_id: selectedLevelTujuan
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKelasTujuan(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const isiKelasTujuan = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "isi-rombel-by-thn", {
        rombel_nama: selectedRombelTujuan,
        tp_id: thnini.id,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSiswaThnIni(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }


  useEffect(() => {
    fetchRombel();
    fetchThnLalu();
    fetchThnIni();
    fetchLevel();

  }, []);


  useEffect(() => {
    fetchKelasAwal();
    if (selectedLevelAwal) {
      if (parseInt(selectedLevelAwal) === "3") {
        setSelectedLevelTujuan("Alumni");
      } else {
        setSelectedLevelTujuan(parseInt(selectedLevelAwal) + 1);
      }
    }
  }, [selectedLevelAwal]);

  useEffect(() => {
    isiKelasAwal();
  }, [selectedRombelAwal]);

  useEffect(() => {
    isiKelasTujuan();
  }, [selectedRombelTujuan]);

  useEffect(() => {
    fetchKelasSaatIni();
  }, [selectedLevelTujuan]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allNis = siswaThnLalu.map((siswa) => siswa.nis);
      setSelectedSiswaAwal(allNis);
      console.log(allNis);
    } else {
      setSelectedSiswaAwal([]);
    }
  };

  const handleSelectSiswa = (e) => {
    const nis = e.target.getAttribute('data-nis');
    if (e.target.checked) {
      setSelectedSiswaAwal([...selectedSiswaAwal, nis]);
      console.log([...selectedSiswaAwal, nis]);
    } else {
      setSelectedSiswaAwal(selectedSiswaAwal.filter((siswaId) => siswaId !== nis));
      console.log(selectedSiswaAwal.filter((siswaId) => siswaId !== nis));
    }
  };

  const prosesNaik = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    if (selectedSiswaAwal.length === 0) {
      toast.error("Silahkan Pilih Siswa Terlebih Dahulu");
      return;
    } else if (selectedRombelTujuan === "") {
      toast.error("Silahkan Pilih Rombel Tujuan Terlebih Dahulu");
      return;
    } else if (selectedSiswaAwal.length === 0 || !selectedRombelTujuan) {
      toast.error("Silahkan Pilih Siswa dan Rombel Tujuan Terlebih Dahulu");
      return;
    }

    try {
      const response = await axios.post(url + "proses-naik-kelas", {
        nis: selectedSiswaAwal,
        tp_id: thnini.id,
        rombel_nama: selectedRombelTujuan,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      toast.success("Proses Kenaikan Berhasil");
      isiKelasTujuan();
    } catch (error) {
      console.log(error);
      toast.error("Proses Kenaikan Gagal");
    }
    setIsLoading(false);
  };

  // Pagination Kelas Awal
  const [currentPageAwal, setCurrentPageAwal] = useState(1);
  const itemsPerPageAwal = 10;

  const handlePageChangeAwal = (pageNumber) => {
    setCurrentPageAwal(pageNumber);
  };

  const indexOfLastItemAwal = currentPageAwal * itemsPerPageAwal;
  const indexOfFirstItemAwal = indexOfLastItemAwal - itemsPerPageAwal;
  const currentItemsAwal = siswaThnLalu.slice(indexOfFirstItemAwal, indexOfLastItemAwal);

  // Pagination Kelas Tujuan
  const [currentPageTujuan, setCurrentPageTujuan] = useState(1);
  const itemsPerPageTujuan = 10;

  const handlePageChangeTujuan = (pageNumber) => {
    setCurrentPageTujuan(pageNumber);
  };

  const indexOfLastItemTujuan = currentPageTujuan * itemsPerPageTujuan;
  const indexOfFirstItemTujuan = indexOfLastItemTujuan - itemsPerPageTujuan;
  const currentItemsTujuan = siswaThnIni.slice(indexOfFirstItemTujuan, indexOfLastItemTujuan);



  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">


            <div className="card-body">
              <div className="row">

                {/* KELAS AWAL */}
                <div className="col-md-6">
                  <b><p>Tahun Ajaran Asal {thnlalu.thn_pel}</p></b>
                  <div className="row mt-3 mb-3">
                    <div className="col-md-6">
                      <div>
                        <label>Kelas / Level</label>
                        <select className="form-control " onChange={(e) => setSelectedLevelAwal(e.target.value)}>
                          <option value="">Pilih Level</option>
                          {level.filter(item => item.id !== 4).map((item) => (
                            <option key={item.id} value={item.id}>{item.level}</option>
                          ))}

                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label>Rombel</label>
                        <select className="form-control" onChange={(e) => setSelectedRombelAwal(e.target.value)}>
                          <option value="">Pilih Kelas</option>
                          {kelasAwal.map((item) => (
                            <option key={item.id} value={item.nama_rombel}>{item.nama_rombel}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <button className="btn btn-primary sm-btn" onClick={prosesNaik}>Proses Kenaikan</button>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={selectedSiswaAwal.length === siswaThnLalu.length}
                            />
                          </th>
                          <th>No</th>
                          <th>Nama Siswa</th>
                          <th>Kelas</th>
                          <th>Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        {currentItemsAwal.length === 0 ? (
                          <tr>
                            <td colSpan="5" className="text-center">Tidak ada data</td>
                          </tr>
                        ) : (
                          currentItemsAwal
                            .map((item, index) => (
                              <tr key={item.id} style={{ height: '10px' }}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedSiswaAwal.includes(item.nis)}
                                    onChange={handleSelectSiswa}
                                    data-nis={item.nis}
                                  />
                                </td>
                                <td>{indexOfFirstItemAwal + index + 1}</td>
                                <td>{item.nama}</td>
                                <td>{item.rombel_nama}</td>
                                <td>
                                  {siswaThnIni.some(siswa => siswa.nis === item.nis) ? (
                                    <span className="badge badge-success">Sudah Diproses</span>
                                  ) : (
                                    <span className="badge badge-danger">Belum Diproses</span>
                                  )}
                                </td>
                              </tr>
                            ))
                        )}
                      </tbody>
                    </table>
                    <div className="pagination d-flex justify-content-end mt-3">
                      {Array.from({ length: Math.ceil(siswaThnLalu.length / itemsPerPageAwal) }, (_, i) => (
                        <button
                          key={i}
                          onClick={() => handlePageChangeAwal(i + 1)}
                          className={`btn ${currentPageAwal === i + 1 ? 'squer-btn btn-outline primary-outline sm-btn' : 'squer-btn btn-outline secondary-outline sm-btn'}`}
                        >
                          {i + 1}
                        </button>
                      ))}

                    </div>
                  </div>
                </div>

                {/* NAIK KE KELAS TUJUAN */}
                <div className="col-md-6">
                  <b><p>Tahun Ajaran saat ini {thnini.thn_pel}</p></b>

                  <div className="row mt-3 mb-3">
                    <div className="col-md-6">
                      <div>
                        <label>Kelas / Level</label>
                        <select className="form-control" value={selectedLevelTujuan} onChange={(e) => setSelectedLevelTujuan(e.target.value)}>
                          <option value="">Pilih Level Thn Lalu</option>

                          {level.map((item) => (
                            <option key={item.id} value={item.id}>{item.level}</option>
                          ))}

                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label>Rombel</label>
                        <select className="form-control" onChange={(e) => setSelectedRombelTujuan(e.target.value)}>
                          <option value="">Pilih Kelas</option>
                          {selectedLevelTujuan === 4 ? (
                            <option value="Alumni">Alumni</option>
                          ) : (
                            kelasTujuan.map((item) => (
                              <option key={item.id} value={item.nama_rombel}>{item.nama_rombel}</option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nama Siswa</th>
                          <th>Kelas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItemsTujuan.length === 0 ? (
                          <tr>
                            <td colSpan="4" className="text-center">Tidak ada data</td>
                          </tr>
                        ) : (
                          currentItemsTujuan.map((item, index) => (
                            <tr key={item.id} style={{ height: '10px' }}>
                              <td>{indexOfFirstItemTujuan + index + 1}</td>
                              <td>{item.nama}</td>
                              <td>{item.rombel_nama}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    <div className="pagination d-flex justify-content-end mt-3">
                      {Array.from({ length: Math.min(4, Math.ceil(siswaThnIni.length / itemsPerPageTujuan)) }, (_, i) => (
                        <button
                          key={i}
                          onClick={() => handlePageChangeTujuan(i + 1)}
                          className={`btn ${currentPageTujuan === i + 1 ? 'squer-btn btn-outline primary-outline sm-btn' : 'squer-btn btn-outline secondary-outline sm-btn'}`}
                        >
                          {i + 1}
                        </button>
                      ))}
                      {Math.ceil(siswaThnIni.length / itemsPerPageTujuan) > 4 && (
                        <button
                          onClick={() => handlePageChangeTujuan(currentPageTujuan + 1)}
                          className="btn squer-btn btn-outline secondary-outline sm-btn"
                          disabled={currentPageTujuan >= Math.ceil(siswaThnIni.length / itemsPerPageTujuan)}
                        >
                          Next
                        </button>
                      )}
                    </div>




                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>



  )
}

export default Kenaikan
