import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';

const SetKurikulum = ({ content, url }) => {
  const [kurikulum, setKurikulum] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tp, setTp] = useState([]);
  const [level, setLevel] = useState('');
  const [curr, setCurr] = useState('');
  const [thnPel, setThnPel] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);



  const fetchKurikulum = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "set-curr", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.length > 0) {
        setKurikulum(response.data.data);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTp = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${url}tahun-pelajaran-aktif`, {
      headers: { Authorization: `Bearer ${token}` },

    });
    setTp(response.data.data);


  }

  const handleSaveKurikulum = async (e) => {
    e.preventDefault();
    const form = e.target.closest('form');
    if (form.checkValidity() === false) {
      e.stopPropagation();
      form.reportValidity();
      return;
    }

    const token = localStorage.getItem("token");
    if (selectedItem) {
      // Update existing kurikulum
      await axios.put(`${url}set-curr/${selectedItem.set_curr_id}`, {
        tp: tp.id,
        level: level,
        kurikulum: curr,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Kurikulum berhasil diperbarui");
    } else {
      // Add new kurikulum
      await axios.post(`${url}set-curr`, {
        tp: tp.id,
        level: level,
        kurikulum: curr,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Kurikulum berhasil ditambahkan");
    }
    setShowModal(false);
    fetchKurikulum();
  };

  const handleEditKurikulum = (item) => {
    console.log(item);
    setSelectedItem(item);
    setLevel(item.level);
    setCurr(item.kurikulum);
    setThnPel(item.thn_pel);
    setShowModal(true);
  };


  useEffect(() => {
    fetchKurikulum();
    getTp();
  }, []);



  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="col-md-3 mt-3 px-2">
              <Button onClick={() => setShowModal(true)}>Tambah</Button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Tahun Pelajaran</th>
                        <th>Level / Kelas</th>
                        <th>Kurikulum</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="5" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        kurikulum.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.thn_pel}</td>
                            <td>{item.level}</td>
                            <td>{item.kurikulum === '2' ? 'Kurikulum Merdeka' : item.kurikulum === '1' ? 'K13' : item.kurikulum}</td>
                            <td>
                              <Button variant="primary" className='sm-btn' onClick={() => handleEditKurikulum(item)}>Edit</Button>
                            </td>
                          </tr>
                        ))
                      )}
                      {isEmpty && (
                        <tr>
                          <td colSpan="5" className="text-center">Data tidak ditemukan</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem ? "Edit Data" : "Tambah Data"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveKurikulum}>
            <Form.Group controlId="thnPel">
              <Form.Label>Tahun Pelajaran</Form.Label>
              <Form.Control type="text" onChange={(e) => setThnPel(e.target.value)} placeholder="Masukkan Tahun Pelajaran" value={tp.thn_pel} readOnly required />
            </Form.Group>
            <Form.Group controlId="level">
              <Form.Label>Level / Kelas</Form.Label>
              <Form.Control as="select" value={level} onChange={(e) => setLevel(e.target.value)} required>
                <option value="">Pilih Kelas</option>
                <option value="1">Kelas 10</option>
                <option value="2">Kelas 11</option>
                <option value="3">Kelas 12</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="kurikulum">
              <Form.Label>Kurikulum</Form.Label>
              <Form.Control as="select" onChange={(e) => setCurr(e.target.value)} value={curr} required>
                <option value="">Pilih Kurikulum</option>
                <option value="1">K13</option>
                <option value="2">Kurikulum Merdeka</option>
              </Form.Control>
            </Form.Group>
            <Button variant="secondary" className='mt-3' onClick={() => setShowModal(false)}>Batal</Button>
            <Button variant="primary" className='mt-3' type="submit">Simpan</Button>
          </Form>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default SetKurikulum
